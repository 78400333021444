import { AlertCircle, Trash2, Undo2 } from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import {
	getDeletedAnesthesias,
	permanentlyDeleteAnesthesia,
	rejectAnesthesiaDeletion,
} from "../services/api";
import { Alert, AlertDescription } from "./ui/alert";
import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
} from "./ui/alert-dialog";
import { Button } from "./ui/button";
import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from "./ui/card";
import { Skeleton } from "./ui/skeleton";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "./ui/table";

interface Anestesia {
	anesthesiaId: string;
	fecha: string;
	complejidad: string;
	anestesista: {
		nombreYapellido: string;
		email: string;
	};
	healthCare: {
		nombre: string;
	};
	patient: {
		nombreYapellido: string;
		dni: string;
	};
	archivo: string;
	anestesistaId: string;
}

const DeletedAnesthesiaList = () => {
	const { user } = useAuth();
	const [anestesias, setAnestesias] = useState<Anestesia[]>([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState<string | null>(null);
	const itemsPerPage = 5;
	const navigate = useNavigate();
	const [showDeleteDialog, setShowDeleteDialog] = useState(false);
	const [anesthesiaToDelete, setAnesthesiaToDelete] = useState<string | null>(
		null
	);
	const [showRejectDialog, setShowRejectDialog] = useState(false);
	const [anesthesiaToReject, setAnesthesiaToReject] = useState<string | null>(
		null
	);

	useEffect(() => {
		fetchDeletedAnestesias();
	}, [currentPage]);

	const fetchDeletedAnestesias = async () => {
		setIsLoading(true);
		setError(null);
		try {
			const data = await getDeletedAnesthesias(currentPage, itemsPerPage);
			setAnestesias(data.anesthesias);
			setTotalPages(data.totalPages);
		} catch (err) {
			setError("Error al cargar las anestesias marcadas para eliminar");
			console.error(err);
		} finally {
			setIsLoading(false);
		}
	};

	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
	};

	const handlePermanentDelete = (id: string) => {
		setAnesthesiaToDelete(id);
		setShowDeleteDialog(true);
	};

	const confirmDelete = async () => {
		if (!anesthesiaToDelete) return;

		try {
			await permanentlyDeleteAnesthesia(anesthesiaToDelete);
			setAnestesias(
				anestesias.filter(
					(anestesia) => anestesia.anesthesiaId !== anesthesiaToDelete
				)
			);
		} catch (err) {
			setError("Error al eliminar permanentemente la anestesia");
			console.error(err);
		}
		setShowDeleteDialog(false);
		setAnesthesiaToDelete(null);
	};

	const handleRejectDeletion = (id: string) => {
		setAnesthesiaToReject(id);
		setShowRejectDialog(true);
	};

	const confirmReject = async () => {
		if (!anesthesiaToReject) return;

		try {
			await rejectAnesthesiaDeletion(anesthesiaToReject);
			setAnestesias(
				anestesias.filter(
					(anestesia) => anestesia.anesthesiaId !== anesthesiaToReject
				)
			);
		} catch (err) {
			setError("Error al rechazar la eliminación de la anestesia");
			console.error(err);
		}
		setShowRejectDialog(false);
		setAnesthesiaToReject(null);
	};

	const formatDate = (dateString: string) => {
		const date = new Date(dateString);
		return `${date.getDate().toString().padStart(2, "0")}/${(
			date.getMonth() + 1
		)
			.toString()
			.padStart(2, "0")}/${date.getFullYear()}`;
	};

	if (!user || user.tipo !== "administrador") {
		navigate("/dashboard");
		return null;
	}

	return (
		<div className="container mx-auto px-4 py-8">
			<Card>
				<CardHeader>
					<CardTitle className="text-2xl font-bold">
						Anestesias Marcadas para Eliminar
					</CardTitle>
					<CardDescription>
						Lista de anestesias pendientes de eliminación definitiva
					</CardDescription>
				</CardHeader>
				<CardContent>
					{error && (
						<Alert variant="destructive" className="mb-6">
							<AlertCircle className="h-4 w-4" />
							<AlertDescription>{error}</AlertDescription>
						</Alert>
					)}

					{isLoading ? (
						<div className="space-y-2">
							{[...Array(5)].map((_, i) => (
								<Skeleton key={i} className="h-16 w-full" />
							))}
						</div>
					) : (
						<div className="rounded-md border">
							<Table>
								<TableHeader>
									<TableRow>
										<TableHead>Fecha</TableHead>
										<TableHead>Paciente</TableHead>
										<TableHead>Obra Social</TableHead>
										<TableHead>Complejidad</TableHead>
										<TableHead>Anestesiólogo</TableHead>
										<TableHead className="text-right">Acciones</TableHead>
									</TableRow>
								</TableHeader>
								<TableBody>
									{anestesias.map((anestesia) => (
										<TableRow key={anestesia.anesthesiaId}>
											<TableCell>{formatDate(anestesia.fecha)}</TableCell>
											<TableCell className="font-medium">
												{anestesia.patient.nombreYapellido}
											</TableCell>
											<TableCell>{anestesia.healthCare.nombre}</TableCell>
											<TableCell>
												<span className="inline-flex items-center rounded-md bg-gray-100 px-2.5 py-0.5 text-sm font-medium text-gray-800">
													{anestesia.complejidad}
												</span>
											</TableCell>
											<TableCell>
												{anestesia.anestesista.nombreYapellido}
											</TableCell>
											<TableCell className="text-right">
												<div className="flex justify-end gap-2">
													<Button
														variant="ghost"
														size="sm"
														onClick={() =>
															handlePermanentDelete(anestesia.anesthesiaId)
														}
														className="text-red-600 hover:text-red-800"
													>
														<Trash2 className="w-4 h-4" />
													</Button>
													<Button
														variant="ghost"
														size="sm"
														onClick={() =>
															handleRejectDeletion(anestesia.anesthesiaId)
														}
														className="text-green-600 hover:text-green-800"
													>
														<Undo2 className="w-4 h-4" />
													</Button>
												</div>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</div>
					)}

					{/* Paginación */}
					<div className="mt-4 flex items-center justify-between">
						<p className="text-sm text-gray-500">
							Mostrando {(currentPage - 1) * itemsPerPage + 1} a{" "}
							{Math.min(currentPage * itemsPerPage, anestesias.length)} de{" "}
							{anestesias.length} registros
						</p>
						<div className="flex gap-2">
							<Button
								variant="outline"
								size="sm"
								onClick={() => handlePageChange(currentPage - 1)}
								disabled={currentPage === 1}
							>
								Anterior
							</Button>
							<Button
								variant="outline"
								size="sm"
								onClick={() => handlePageChange(currentPage + 1)}
								disabled={currentPage === totalPages}
							>
								Siguiente
							</Button>
						</div>
					</div>
				</CardContent>
			</Card>

			<AlertDialog open={showDeleteDialog} onOpenChange={setShowDeleteDialog}>
				<AlertDialogContent>
					<AlertDialogHeader>
						<AlertDialogTitle>¿Estás seguro?</AlertDialogTitle>
						<AlertDialogDescription>
							Esta acción no se puede deshacer. La anestesia será eliminada
							permanentemente del sistema.
						</AlertDialogDescription>
					</AlertDialogHeader>
					<AlertDialogFooter>
						<AlertDialogCancel>Cancelar</AlertDialogCancel>
						<AlertDialogAction
							onClick={confirmDelete}
							className="bg-destructive text-destructive-foreground hover:bg-destructive/90"
						>
							Eliminar Permanentemente
						</AlertDialogAction>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialog>

			<AlertDialog open={showRejectDialog} onOpenChange={setShowRejectDialog}>
				<AlertDialogContent>
					<AlertDialogHeader>
						<AlertDialogTitle>¿Estás seguro?</AlertDialogTitle>
						<AlertDialogDescription>
							¿Deseas rechazar la eliminación de esta anestesia? La anestesia
							volverá a estar activa en el sistema.
						</AlertDialogDescription>
					</AlertDialogHeader>
					<AlertDialogFooter>
						<AlertDialogCancel>Cancelar</AlertDialogCancel>
						<AlertDialogAction onClick={confirmReject}>
							Rechazar Eliminación
						</AlertDialogAction>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialog>
		</div>
	);
};

export default DeletedAnesthesiaList;
