import {
	Activity,
	Building2,
	FilePlus,
	FileQuestion,
	FileText,
	LogOut,
	Menu,
	Settings,
	User,
	UserCog,
	UserPlus,
	Users,
	X,
} from "lucide-react";
import { useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

const Dashboard = () => {
	const { logout, user } = useAuth();
	const [isOpen, setIsOpen] = useState(false);

	const menuItems = [
		{
			title: "Principal",
			items: [
				{
					name: "Dashboard",
					icon: <Activity className="w-4 h-4" />,
					path: "/",
				  },
				{
					name: "Anestesias",
					icon: <FileText className="w-4 h-4" />,
					path: "/anesthesias",
				},
				{
					name: "Nueva Anestesia",
					icon: <FilePlus className="w-4 h-4" />,
					path: "/anesthesias/new",
				},
				{
					name: "Pacientes",
					icon: <Users className="w-4 h-4" />,
					path: "/patients",
				},
				{
					name: "Reportes",
					icon: <Activity className="w-4 h-4" />,
					path: "/reports",
				},
			],
		},
	];

	if (user?.tipo === "administrador") {
		menuItems.push({
			title: "Administración",
			items: [
				{
					name: "Usuarios",
					icon: <UserCog className="w-4 h-4" />,
					path: "/users",
				},
				{
					name: "Nuevo Usuario",
					icon: <UserPlus className="w-4 h-4" />,
					path: "/users/new",
				},
				{
					name: "Obras Sociales",
					icon: <Building2 className="w-4 h-4" />,
					path: "/health-care",
				},
				{
					name: "Anestesias Pendientes",
					icon: <FileQuestion className="w-4 h-4" />,
					path: "/anesthesias/deleted",
				},
			],
		});
	}

	return (
		<div className="flex h-screen bg-gray-100">
			{/* Sidebar */}
			<aside
				className={`
					fixed inset-y-0 left-0 z-50 w-64 bg-white shadow-lg transform transition-transform duration-300 ease-in-out
					${isOpen ? "translate-x-0" : "-translate-x-full"}
					md:relative md:translate-x-0
				`}
			>
				{/* Logo y perfil */}
				<div className="flex flex-col h-full">
					<div className="p-4 bg-blue-600">
						<h1 className="text-xl font-bold text-white">Anestrack</h1>
						<div className="mt-4 flex items-center text-white">
							<div className="w-8 h-8 rounded-full bg-blue-500 flex items-center justify-center">
								{user?.tipo === "administrador" ? (
									<Settings className="w-4 h-4" />
								) : (
									<User className="w-4 h-4" />
								)}
							</div>
							<span className="ml-2 text-sm font-medium">
								{user?.nombre_y_apellido}
							</span>
						</div>
					</div>

					{/* Menú */}
					<nav className="flex-1 overflow-y-auto p-4">
						{menuItems.map((section) => (
							<div key={section.title} className="mb-6">
								<h2 className="text-xs font-semibold text-gray-400 uppercase tracking-wider mb-3">
									{section.title}
								</h2>
								<ul className="space-y-1">
									{section.items.map((item) => (
										<li key={item.path}>
											<Link
												to={item.path}
												onClick={() => setIsOpen(false)}
												className="flex items-center px-3 py-2 text-sm text-gray-700 rounded-lg hover:bg-gray-100 transition-colors"
											>
												{item.icon}
												<span className="ml-3">{item.name}</span>
											</Link>
										</li>
									))}
								</ul>
							</div>
						))}
					</nav>

					{/* Botón de cerrar sesión */}
					<div className="p-4 border-t">
						<button
							onClick={() => {
								logout();
								setIsOpen(false);
							}}
							className="flex items-center w-full px-3 py-2 text-sm text-red-600 rounded-lg hover:bg-red-50 transition-colors"
						>
							<LogOut className="w-4 h-4" />
							<span className="ml-3">Cerrar Sesión</span>
						</button>
					</div>
				</div>
			</aside>

			{/* Contenido principal */}
			<main className="flex-1 overflow-auto">
				<div className="p-4 md:p-8">
					{/* Botón de menú móvil */}
					<button
						onClick={() => setIsOpen(!isOpen)}
						className="fixed top-4 left-4 z-50 md:hidden bg-white p-2 rounded-lg shadow-lg"
					>
						{isOpen ? (
							<X className="w-6 h-6 text-gray-600" />
						) : (
							<Menu className="w-6 h-6 text-gray-600" />
						)}
					</button>

					{/* Overlay para móvil */}
					{isOpen && (
						<div
							className="fixed inset-0 bg-black bg-opacity-50 z-40 md:hidden"
							onClick={() => setIsOpen(false)}
						/>
					)}

					<div className="mt-16 md:mt-0">
						<Outlet />
					</div>
				</div>
			</main>
		</div>
	);
};

export default Dashboard;
