import {
	ChevronLeft,
	ChevronRight,
	Eye,
	FileText,
	Plus,
	Trash2,
} from "lucide-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { deleteAnesthesia, getAnesthesias } from "../services/api";
import { Alert, AlertDescription } from "./ui/alert";
import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
} from "./ui/alert-dialog";
import { Badge } from "./ui/badge";
import { Button } from "./ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Skeleton } from "./ui/skeleton";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "./ui/table";

interface Anestesia {
	anesthesiaId: string;
	fecha: string;
	complejidad: string;
	anestesista: {
		nombreYapellido: string;
		email: string;
	};
	patient: {
		nombreYapellido: string;
		dni: string;
	};
	healthCare: {
		nombre: string;
	};
	archivo: string;
	anestesistaId: string;
}

const AnesthesiaList = () => {
	const { user } = useAuth();
	const [anestesias, setAnestesias] = useState<Anestesia[]>([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState<string | null>(null);
	const [patientSearch, setPatientSearch] = useState("");
	const itemsPerPage = 5;
	const navigate = useNavigate();
	const [anesthesiaToDelete, setAnesthesiaToDelete] = useState<{
		id: string;
		anestesistaId: string;
	} | null>(null);

	useEffect(() => {
		fetchAnestesias();
	}, [currentPage]);

	const fetchAnestesias = async () => {
		setIsLoading(true);
		setError(null);
		try {
			const data = await getAnesthesias(
				currentPage,
				itemsPerPage,
				patientSearch
			);
			console.log(data);

			setAnestesias(data.anesthesias);
			setTotalPages(data.totalPages);
		} catch (err) {
			setError("Error al cargar las anestesias");
			console.error(err);
		} finally {
			setIsLoading(false);
		}
	};

	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
	};

	const handleViewAnesthesia = (id: string) => {
		navigate(`/anesthesias/${id}`);
	};

	const handleDeleteAnesthesia = async (id: string, anestesistaId: string) => {
		if (user.userId === anestesistaId || user.tipo === "administrador") {
			setAnesthesiaToDelete({ id, anestesistaId });
		} else {
			alert("No tienes permiso para eliminar esta anestesia.");
		}
	};

	const confirmDelete = async () => {
		if (!anesthesiaToDelete) return;

		try {
			await deleteAnesthesia(anesthesiaToDelete.id);
			setAnestesias(
				anestesias.filter(
					(anestesia) => anestesia.anesthesiaId !== anesthesiaToDelete.id
				)
			);
		} catch (err) {
			setError("Error al eliminar la anestesia");
			console.error(err);
		}
		setAnesthesiaToDelete(null);
	};

	const handleRegisterAnesthesia = () => {
		navigate("/anesthesias/new");
	};

	const formatDate = (dateString: string) => {
		const date = new Date(dateString);
		return `${date.getDate().toString().padStart(2, "0")}/${(
			date.getMonth() + 1
		)
			.toString()
			.padStart(2, "0")}/${date.getFullYear()}`;
	};

	const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setCurrentPage(1); // Reset to first page when searching
		fetchAnestesias();
	};

	if (error) {
		return (
			<Card className="mx-auto max-w-7xl p-4">
				<div className="bg-red-50 text-red-700 p-4 rounded-lg">
					<p>Error: {error}</p>
				</div>
			</Card>
		);
	}

	return (
		<div className="container mx-auto px-4 py-4 md:py-8">
			<Card>
				<CardHeader className="flex flex-col sm:flex-row justify-between gap-4">
					<CardTitle className="text-xl md:text-2xl">Anestesias</CardTitle>
					<Button
						onClick={() => navigate("/anesthesias/new")}
						className="hidden md:flex items-center gap-2"
					>
						<Plus className="w-4 h-4" />
						Nueva Anestesia
					</Button>
				</CardHeader>

				<CardContent>
					{error && (
						<Alert variant="destructive" className="mb-6">
							<AlertDescription>{error}</AlertDescription>
						</Alert>
					)}

					{isLoading ? (
						<div className="space-y-4">
							{[...Array(5)].map((_, i) => (
								<Skeleton key={i} className="h-24 w-full" />
							))}
						</div>
					) : (
						<>
							{/* Vista móvil */}
							<div className="md:hidden space-y-4">
								{anestesias.map((anestesia) => (
									<Card key={anestesia.anesthesiaId}>
										<CardContent className="pt-6">
											<div className="space-y-2">
												<div className="flex justify-between items-start">
													<div>
														<p className="text-sm text-muted-foreground">
															<strong>Fecha:</strong>{" "}
															{formatDate(anestesia.fecha)}
														</p>
														<p className="font-medium mt-1">
															<strong>Paciente:</strong>{" "}
															{anestesia.patient.nombreYapellido}
														</p>
														<p className="text-sm">
															<strong>DNI:</strong> {anestesia.patient.dni}
														</p>
														<p className="text-sm">
															<strong>Obra Social:</strong>{" "}
															{anestesia.healthCare.nombre}
														</p>
														<p className="text-sm">
															<strong>Complejidad:</strong>{" "}
															{anestesia.complejidad}
														</p>
														<p className="text-sm">
															<strong>Anestesiólogo:</strong>{" "}
															{anestesia.anestesista.nombreYapellido}
														</p>
														<p className="text-sm">
															<strong>Archivo:</strong>{" "}
															{anestesia.archivo && (
																<FileText className="inline w-4 h-4 text-gray-500" />
															)}
														</p>
													</div>
												</div>

												<div className="flex justify-end gap-2 mt-4">
													<Button
														variant="ghost"
														size="sm"
														onClick={() =>
															handleViewAnesthesia(anestesia.anesthesiaId)
														}
													>
														<Eye className="w-4 h-4" />
													</Button>
													{(user.userId === anestesia.anestesistaId ||
														user.tipo === "administrador") && (
														<Button
															variant="ghost"
															size="sm"
															onClick={() =>
																handleDeleteAnesthesia(
																	anestesia.anesthesiaId,
																	anestesia.anestesistaId
																)
															}
															className="text-red-600 hover:text-red-800"
														>
															<Trash2 className="w-4 h-4" />
														</Button>
													)}
												</div>
											</div>
										</CardContent>
									</Card>
								))}
							</div>

							{/* Vista desktop */}
							<div className="hidden md:block">
								<div className="rounded-md border">
									<Table>
										<TableHeader>
											<TableRow>
												<TableHead>Fecha</TableHead>
												<TableHead>Paciente</TableHead>
												<TableHead>DNI</TableHead>
												<TableHead>Obra Social</TableHead>
												<TableHead>Complejidad</TableHead>
												<TableHead>Anestesiólogo</TableHead>
												<TableHead>Archivo</TableHead>
												<TableHead className="text-right">Acciones</TableHead>
											</TableRow>
										</TableHeader>
										<TableBody>
											{anestesias.map((anestesia) => (
												<TableRow key={anestesia.anesthesiaId}>
													<TableCell>{formatDate(anestesia.fecha)}</TableCell>
													<TableCell className="font-medium">
														{anestesia.patient.nombreYapellido}
													</TableCell>
													<TableCell>{anestesia.patient.dni}</TableCell>
													<TableCell>{anestesia.healthCare.nombre}</TableCell>
													<TableCell>
														<Badge variant="secondary">
															{anestesia.complejidad}
														</Badge>
													</TableCell>
													<TableCell>
														{anestesia.anestesista.nombreYapellido}
													</TableCell>
													<TableCell>
														{anestesia.archivo && (
															<FileText className="w-4 h-4 text-gray-500" />
														)}
													</TableCell>
													<TableCell className="text-right">
														<div className="flex justify-end gap-2">
															<Button
																variant="ghost"
																size="sm"
																onClick={() =>
																	handleViewAnesthesia(anestesia.anesthesiaId)
																}
															>
																<Eye className="w-4 h-4" />
															</Button>
															{(user.userId === anestesia.anestesistaId ||
																user.tipo === "administrador") && (
																<Button
																	variant="ghost"
																	size="sm"
																	onClick={() =>
																		handleDeleteAnesthesia(
																			anestesia.anesthesiaId,
																			anestesia.anestesistaId
																		)
																	}
																	className="text-red-600 hover:text-red-800"
																>
																	<Trash2 className="w-4 h-4" />
																</Button>
															)}
														</div>
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</div>
							</div>
						</>
					)}

					{/* Paginación */}
					<div className="mt-4 flex flex-col sm:flex-row items-center justify-between gap-4">
						<p className="text-sm text-muted-foreground order-2 sm:order-1">
							Mostrando {(currentPage - 1) * itemsPerPage + 1} a{" "}
							{Math.min(currentPage * itemsPerPage, anestesias.length)} de{" "}
							{anestesias.length} registros
						</p>
						<div className="flex gap-2 order-1 sm:order-2">
							<Button
								variant="outline"
								size="sm"
								onClick={() => handlePageChange(currentPage - 1)}
								disabled={currentPage === 1}
							>
								<ChevronLeft className="w-4 h-4" />
							</Button>
							<Button
								variant="outline"
								size="sm"
								onClick={() => handlePageChange(currentPage + 1)}
								disabled={currentPage === totalPages}
							>
								<ChevronRight className="w-4 h-4" />
							</Button>
						</div>
					</div>
				</CardContent>
			</Card>

			{/* Botón flotante para móvil */}
			<Button
				onClick={() => navigate("/anesthesias/new")}
				className="md:hidden fixed bottom-6 right-6 rounded-full w-14 h-14 shadow-lg"
			>
				<Plus className="w-6 h-6" />
			</Button>

			<AlertDialog
				open={!!anesthesiaToDelete}
				onOpenChange={() => setAnesthesiaToDelete(null)}
			>
				<AlertDialogContent>
					<AlertDialogHeader>
						<AlertDialogTitle>¿Estás seguro?</AlertDialogTitle>
						<AlertDialogDescription>
							Esta acción no se puede deshacer. Se eliminará permanentemente la
							anestesia del sistema.
						</AlertDialogDescription>
					</AlertDialogHeader>
					<AlertDialogFooter>
						<AlertDialogCancel>Cancelar</AlertDialogCancel>
						<AlertDialogAction onClick={confirmDelete}>
							Eliminar
						</AlertDialogAction>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialog>
		</div>
	);
};

export default AnesthesiaList;
