import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { ArrowLeft, Download, Loader2, Search } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { getAnesthesiaReport, getUsers } from "../services/api";
import { Alert, AlertDescription } from "./ui/alert";
import { Badge } from "./ui/badge";
import { Button } from "./ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "./ui/select";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "./ui/table";

export interface ReportFilters {
	dateFrom?: string;
	dateTo?: string;
	anestesistaId?: string;
	pagado?: boolean;
	planificadas?: boolean;
	vencida?: boolean;
	reportType?: "all" | "pending" | "applied";
}

interface AnesthesiaReport {
	fecha: string;
	patient: {
		nombreYapellido: string;
		dni: string;
	};
	codigo: string;
	complejidad: string;
	precio: number | null;
	vencimientoPago: string | null;
	pagado: boolean;
}

interface Anesthetist {
	userId: string;
	nombreYapellido: string;
}

const Reports: React.FC = () => {
	const { user } = useAuth();
	const navigate = useNavigate();
	const [filters, setFilters] = useState<ReportFilters>({
		dateFrom: "",
		dateTo: "",
		anestesistaId: "",
		planificadas: undefined,
		pagado: undefined,
		vencida: undefined,
		reportType: "all",
	});
	const [reportData, setReportData] = useState<AnesthesiaReport[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const [anestesistaId, setAnestesistaId] = useState<string>("");
	const [anesthetists, setAnesthetists] = useState<Anesthetist[]>([]);

	useEffect(() => {
		const fetchAnesthetists = async () => {
			try {
				if (user.tipo === "administrador") {
					const data = await getUsers(1, 0);
					setAnesthetists(data.users);
				} else {
					setAnestesistaId(user.userId);
				}
			} catch (err) {
				setError("Error al obtener anestesistas");
			}
		};
		fetchAnesthetists();
	}, []);

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		setIsLoading(true);
		setError(null);
		try {
			const data = await getAnesthesiaReport({
				...filters,
				anestesistaId: anestesistaId || undefined,
			});
			setReportData(data.anesthesias);
		} catch (err) {
			setError("Error al obtener el reporte");
		} finally {
			setIsLoading(false);
		}
	};

	const formatDate = (dateString: string | null): string => {
		if (!dateString) return "N/A";
		const date = new Date(dateString);
		return date.toLocaleDateString("es-ES", {
			year: "numeric",
			month: "2-digit",
			day: "2-digit",
		});
	};

	const generatePDF = () => {
		const doc = new jsPDF();
		const reportType = {
			all: "Todos",
			pending: "Pendientes",
			applied: "Aplicadas",
		};
		// Configuración del título
		doc.setFontSize(20);
		doc.setTextColor(44, 62, 80);
		doc.text(
			`Reporte de Anestesias - ${
				filters.reportType ? reportType[filters.reportType] : "Todas"
			}`,
			14,
			20
		);

		// Agregar fecha de generación
		doc.setFontSize(10);
		doc.setTextColor(128, 128, 128);
		const fechaGeneracion = new Date().toLocaleDateString("es-ES", {
			year: "numeric",
			month: "long",
			day: "numeric",
			hour: "2-digit",
			minute: "2-digit",
		});
		doc.text(`Generado el: ${fechaGeneracion}`, 14, 30);

		// Configuración de la tabla
		autoTable(doc, {
			startY: 40,
			head: [["Fecha", "Paciente", "Código", "Precio", "Vto", "Pagado"]],
			body: reportData.map((item) => [
				formatDate(item.fecha),
				item.patient.nombreYapellido,
				item.codigo,
				item.precio ? `$${item.precio.toFixed(2)}` : "N/A",
				formatDate(item.vencimientoPago),
				item.pagado ? "Sí" : "No",
			]),
			styles: {
				fontSize: 10,
				cellPadding: 5,
			},
			headStyles: {
				fillColor: [44, 62, 80],
				textColor: 255,
				fontStyle: "bold",
			},
			alternateRowStyles: {
				fillColor: [245, 245, 245],
			},
			columnStyles: {
				0: { cellWidth: 30 }, // Fecha
				1: { cellWidth: 40 }, // Paciente
				2: { cellWidth: 25 }, // Código
				4: { cellWidth: 30 }, // Precio
				5: { cellWidth: 30 }, // Vencimiento
				6: { cellWidth: 15 }, // Pagado
			},
			didDrawPage: (data) => {
				// Agregar número de página en cada página
				doc.setFontSize(10);
				doc.setTextColor(128, 128, 128);
				doc.text(
					`Página ${doc.getCurrentPageInfo().pageNumber}`,
					doc.internal.pageSize.width - 20,
					doc.internal.pageSize.height - 10,
					{ align: "right" }
				);
			},
			didDrawCell: (data) => {
				// Agregar estilos especiales para la columna de estado
				if (data.section === "body" && data.column.index === 6) {
					const cell = data.cell;
					const text = cell.text[0];
					if (text === "Sí") {
						cell.styles.textColor = [46, 125, 50]; // Verde para pagado
					} else {
						cell.styles.textColor = [211, 47, 47]; // Rojo para no pagado
					}
				}
			},
			willDrawPage: (data) => {
				// Reiniciar márgenes en cada página nueva
				data.settings.margin = { top: 40, right: 14, bottom: 40, left: 14 };
			},
		});

		// Calcular estadísticas
		const totalAnestesias = reportData.length;
		const anestesiasPagadas = reportData.filter((item) => item.pagado).length;
		const totalMonto = reportData.reduce(
			(sum, item) => sum + (item.precio || 0),
			0
		);

		// Agregar nueva página para el resumen si es necesario
		const finalY = (doc as any).lastAutoTable.finalY;
		const pageHeight = doc.internal.pageSize.height;

		if (finalY > pageHeight - 100) {
			doc.addPage();
		} else {
			doc.setDrawColor(200, 200, 200);
			doc.line(14, finalY + 10, doc.internal.pageSize.width - 14, finalY + 10);
		}

		// Crear cuadro de resumen
		const startY = finalY > pageHeight - 100 ? 40 : finalY + 20;

		autoTable(doc, {
			startY: startY,
			head: [["Resumen del Reporte"]],
			body: [
				[`Total de anestesias: ${totalAnestesias}`],
				[
					`Anestesias pagadas: ${anestesiasPagadas} (${(
						(anestesiasPagadas / totalAnestesias) *
						100
					).toFixed(1)}%)`,
				],
				[`Anestesias pendientes: ${totalAnestesias - anestesiasPagadas}`],
				[`Monto total: $${totalMonto.toFixed(2)}`],
			],
			styles: {
				fontSize: 10,
				cellPadding: 5,
			},
			headStyles: {
				fillColor: [44, 62, 80],
				textColor: 255,
				fontStyle: "bold",
				halign: "center",
			},
			theme: "grid",
			margin: { left: 14, right: 14 },
			tableWidth: "auto",
		});

		// Agregar pie de página
		doc.setFontSize(8);
		doc.setTextColor(128, 128, 128);
		doc.text(
			"Este documento es un reporte generado automáticamente por el sistema.",
			14,
			doc.internal.pageSize.height - 10
		);

		doc.save("reporte_anestesias.pdf");
	};

	const getTomorrowDate = () => {
		const tomorrow = new Date();
		tomorrow.setDate(tomorrow.getDate() + 1);
		return tomorrow.toISOString().split("T")[0];
	};

	const getTodayDate = () => {
		return new Date().toISOString().split("T")[0];
	};

	return (
		<div className="container mx-auto px-4 py-8">
			<Card>
				<CardHeader className="flex flex-row items-center justify-between">
					<div className="flex items-center gap-4">
						<Button
							variant="ghost"
							onClick={() => navigate("/")}
							className="flex items-center gap-2"
						>
							<ArrowLeft className="w-4 h-4" />
							Volver
						</Button>
						<CardTitle>Reportes de Anestesias</CardTitle>
					</div>
				</CardHeader>

				<CardContent>
					{error && (
						<Alert variant="destructive" className="mb-6">
							<AlertDescription>{error}</AlertDescription>
						</Alert>
					)}

					<form onSubmit={handleSubmit} className="space-y-6">
						<div className="space-y-2">
							<Label htmlFor="reportType">Tipo de Reporte</Label>
							<Select
								value={filters.reportType || "all"}
								onValueChange={(value: "all" | "pending" | "applied") => {
									if (value === "pending") {
										setFilters((prev) => ({
											...prev,
											reportType: value,
											dateFrom: getTomorrowDate(),
											dateTo: "",
										}));
									} else if (value === "applied") {
										setFilters((prev) => ({
											...prev,
											reportType: value,
											dateFrom: "",
											dateTo: getTodayDate(),
										}));
									} else {
										setFilters((prev) => ({
											...prev,
											reportType: value,
											dateFrom: "",
											dateTo: "",
										}));
									}
								}}
							>
								<SelectTrigger>
									<SelectValue placeholder="Seleccione tipo de reporte" />
								</SelectTrigger>
								<SelectContent>
									<SelectItem value="all">Todos</SelectItem>
									<SelectItem value="pending">Pendientes</SelectItem>
									<SelectItem value="applied">Aplicadas</SelectItem>
								</SelectContent>
							</Select>
						</div>

						<div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
							<div className="space-y-2">
								<Label htmlFor="dateFrom">Fecha Desde</Label>
								<Input
									type="date"
									id="dateFrom"
									name="dateFrom"
									value={filters.dateFrom}
									onChange={(e) =>
										setFilters((prev) => ({
											...prev,
											dateFrom: e.target.value,
										}))
									}
									required
									disabled={filters.reportType !== "all"}
								/>
							</div>
							<div className="space-y-2">
								<Label htmlFor="dateTo">Fecha Hasta</Label>
								<Input
									type="date"
									id="dateTo"
									name="dateTo"
									value={filters.dateTo}
									onChange={(e) =>
										setFilters((prev) => ({ ...prev, dateTo: e.target.value }))
									}
									required
									disabled={filters.reportType !== "all"}
								/>
							</div>
						</div>

						<div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
							{anesthetists.length > 0 && (
								<div className="space-y-2">
									<Label htmlFor="anestesistaId">Anestesista</Label>
									<Select
										value={anestesistaId}
										onValueChange={(value) =>
											setAnestesistaId(value === "all" ? "" : value)
										}
									>
										<SelectTrigger>
											<SelectValue placeholder="Seleccione un anestesista" />
										</SelectTrigger>
										<SelectContent>
											<SelectItem value="all">Todos</SelectItem>
											{anesthetists.map((anesthetist) => (
												<SelectItem
													key={anesthetist.userId}
													value={anesthetist.userId}
												>
													{anesthetist.nombreYapellido}
												</SelectItem>
											))}
										</SelectContent>
									</Select>
								</div>
							)}

							<div className="space-y-2">
								<Label htmlFor="pagado">Estado de Pago</Label>
								<Select
									value={filters.pagado?.toString() ?? "all"}
									onValueChange={(value) =>
										setFilters((prev) => ({
											...prev,
											pagado: value === "all" ? undefined : value === "true",
										}))
									}
								>
									<SelectTrigger>
										<SelectValue placeholder="Seleccione estado" />
									</SelectTrigger>
									<SelectContent>
										<SelectItem value="all">Todos</SelectItem>
										<SelectItem value="true">Pagado</SelectItem>
										<SelectItem value="false">No Pagado</SelectItem>
									</SelectContent>
								</Select>
							</div>

							<div className="space-y-2">
								<Label htmlFor="vencida">Estado de Vencimiento</Label>
								<Select
									value={filters.vencida?.toString() ?? "all"}
									onValueChange={(value) =>
										setFilters((prev) => ({
											...prev,
											vencida: value === "all" ? undefined : value === "true",
										}))
									}
								>
									<SelectTrigger>
										<SelectValue placeholder="Seleccione estado" />
									</SelectTrigger>
									<SelectContent>
										<SelectItem value="all">Todos</SelectItem>
										<SelectItem value="true">Vencida</SelectItem>
										<SelectItem value="false">No Vencida</SelectItem>
									</SelectContent>
								</Select>
							</div>
						</div>

						<div className="flex justify-end gap-4">
							<Button type="submit" disabled={isLoading}>
								{isLoading ? (
									<>
										<Loader2 className="w-4 h-4 mr-2 animate-spin" />
										Generando...
									</>
								) : (
									<>
										<Search className="w-4 h-4 mr-2" />
										Generar Reporte
									</>
								)}
							</Button>
						</div>
					</form>

					{reportData.length > 0 && (
						<div className="mt-8 space-y-4">
							<div className="flex justify-between items-center">
								<h3 className="text-lg font-semibold">
									Resultados del Reporte
								</h3>
								<Button onClick={generatePDF} variant="outline">
									<Download className="w-4 h-4 mr-2" />
									Exportar a PDF
								</Button>
							</div>

							<div className="rounded-md border">
								<Table>
									<TableHeader>
										<TableRow>
											<TableHead>Fecha</TableHead>
											<TableHead>Paciente</TableHead>
											<TableHead>Código</TableHead>
											<TableHead>Complejidad</TableHead>
											<TableHead>Precio</TableHead>
											<TableHead>Vencimiento</TableHead>
											<TableHead>Estado</TableHead>
										</TableRow>
									</TableHeader>
									<TableBody>
										{reportData.map((item, index) => (
											<TableRow key={index}>
												<TableCell>{formatDate(item.fecha)}</TableCell>
												<TableCell>{item.patient.nombreYapellido}</TableCell>
												<TableCell>{item.codigo}</TableCell>
												<TableCell>
													<Badge variant="outline">{item.complejidad}</Badge>
												</TableCell>
												<TableCell>
													${item.precio ? item.precio.toFixed(2) : "N/A"}
												</TableCell>
												<TableCell>
													{formatDate(item.vencimientoPago)}
												</TableCell>
												<TableCell>
													<Badge
														variant={item.pagado ? "default" : "destructive"}
													>
														{item.pagado ? "Pagado" : "No Pagado"}
													</Badge>
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</div>
						</div>
					)}
				</CardContent>
			</Card>
		</div>
	);
};

export default Reports;
