import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { createUser, getUser, updateUser } from "../services/api";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./ui/select";
import { Alert, AlertDescription } from "./ui/alert";
import { ArrowLeft, Loader2, Save } from "lucide-react";
import { Skeleton } from "./ui/skeleton";
import { Switch } from "./ui/switch";

export interface UserFormData {
	nombreYapellido: string;
	email: string;
	password: string;
	tipo: "administrador" | "anestesista";
	estado?: boolean;
}

interface UserFormProps {
	isEditing?: boolean;
}

const UserForm: React.FC<UserFormProps> = ({ isEditing = false }) => {
	const { id } = useParams<{ id: string }>();
	const navigate = useNavigate();
	const { user: currentUser } = useAuth();
	const [formData, setFormData] = useState<UserFormData>({
		nombreYapellido: "",
		email: "",
		password: "",
		tipo: "anestesista",
		estado: true,
	});
	const [originalData, setOriginalData] = useState<UserFormData | null>(null);
	const [error, setError] = useState<string>("");
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		if (isEditing && id) {
			fetchUser();
		}
	}, [isEditing, id]);

	const fetchUser = async () => {
		setIsLoading(true);
		try {
			const userData = await getUser(id!);
			const transformedData: UserFormData = {
				...userData,
				password: "",
			};
			setFormData(transformedData);
			setOriginalData(transformedData);
		} catch (err: any) {
			setError(err.message);
		} finally {
			setIsLoading(false);
		}
	};

	const handleChange = (
		name: string,
		value: string | boolean
	) => {
		setFormData(prev => ({ ...prev, [name]: value }));
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		setIsLoading(true);
		setError("");

		try {
			if (isEditing && id) {
				const changedData = getChangedData();
				if (Object.keys(changedData).length > 0) {
					await updateUser(id, changedData);
				}
			} else {
				await createUser(formData);
			}
			navigate("/users");
		} catch (err: any) {
			setError(err.message);
		} finally {
			setIsLoading(false);
		}
	};

	const getChangedData = () => {
		if (!originalData) return formData;

		const changedData: Partial<UserFormData> = {};
		(Object.keys(formData) as Array<keyof UserFormData>).forEach((key) => {
			if (formData[key] !== originalData[key]) {
				(changedData as any)[key] = formData[key];
			}
		});

		if (formData.password) {
			changedData.password = formData.password;
		}

		return changedData;
	};

	if (!currentUser || currentUser.tipo !== "administrador") {
		navigate("/dashboard");
		return null;
	}

	if (isLoading && isEditing) {
		return (
			<Card className="max-w-2xl mx-auto">
				<CardHeader>
					<Skeleton className="h-8 w-64" />
				</CardHeader>
				<CardContent>
					<div className="space-y-4">
						<Skeleton className="h-10 w-full" />
						<Skeleton className="h-10 w-full" />
						<Skeleton className="h-10 w-full" />
					</div>
				</CardContent>
			</Card>
		);
	}

	return (
		<div className="container mx-auto px-4 py-4 md:py-8">
			<Card className="max-w-2xl mx-auto">
				<CardHeader className="space-y-2">
					<div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
						<Button
							variant="ghost"
							onClick={() => navigate("/users")}
							className="self-start"
						>
							<ArrowLeft className="w-4 h-4 mr-2" />
							Volver
						</Button>
						<CardTitle className="text-xl md:text-2xl">
							{isEditing ? "Editar" : "Registrar"} Usuario
						</CardTitle>
					</div>
				</CardHeader>

				<CardContent>
					{error && (
						<Alert variant="destructive" className="mb-6">
							<AlertDescription>{error}</AlertDescription>
						</Alert>
					)}

					<form onSubmit={handleSubmit} className="space-y-6">
						<div className="grid grid-cols-1 gap-4">
							<div className="space-y-2">
								<Label htmlFor="nombreYapellido">Nombre y Apellido</Label>
								<Input
									id="nombreYapellido"
									value={formData.nombreYapellido}
									onChange={(e) => handleChange("nombreYapellido", e.target.value)}
									placeholder="Ingrese nombre y apellido"
									required
								/>
							</div>

							<div className="space-y-2">
								<Label htmlFor="email">Email</Label>
								<Input
									type="email"
									id="email"
									value={formData.email}
									onChange={(e) => handleChange("email", e.target.value)}
									placeholder="correo@ejemplo.com"
									required
								/>
							</div>

							<div className="space-y-2">
								<Label htmlFor="password">
									Contraseña {isEditing && "(Dejar en blanco para mantener la actual)"}
								</Label>
								<Input
									type="password"
									id="password"
									value={formData.password}
									onChange={(e) => handleChange("password", e.target.value)}
									placeholder="••••••••"
									required={!isEditing}
								/>
							</div>

							<div className="space-y-2">
								<Label htmlFor="tipo">Tipo de Usuario</Label>
								<Select
									value={formData.tipo}
									onValueChange={(value) => handleChange("tipo", value)}
								>
									<SelectTrigger>
										<SelectValue placeholder="Seleccione tipo" />
									</SelectTrigger>
									<SelectContent>
										<SelectItem value="anestesista">Anestesista</SelectItem>
										<SelectItem value="administrador">Administrador</SelectItem>
									</SelectContent>
								</Select>
							</div>

							{isEditing && (
								<div className="flex items-center space-x-2">
									<Switch
										id="estado"
										checked={formData.estado}
										onCheckedChange={(checked) => handleChange("estado", checked)}
									/>
									<Label htmlFor="estado">Usuario Activo</Label>
								</div>
							)}
						</div>

						<div className="flex flex-col sm:flex-row justify-end gap-3 mt-6">
							<Button
								type="button"
								variant="outline"
								onClick={() => navigate("/users")}
								className="w-full sm:w-auto"
							>
								Cancelar
							</Button>
							<Button
								type="submit"
								disabled={isLoading}
								className="w-full sm:w-auto"
							>
								{isLoading ? (
									<>
										<Loader2 className="w-4 h-4 mr-2 animate-spin" />
										Guardando...
									</>
								) : (
									<>
										<Save className="w-4 h-4 mr-2" />
										{isEditing ? "Actualizar" : "Registrar"}
									</>
								)}
							</Button>
						</div>
					</form>
				</CardContent>
			</Card>
		</div>
	);
};

export default UserForm;
