import { ChevronLeft, ChevronRight, Pencil, Plus, Trash2 } from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { deleteUser, getUsers } from "../services/api";
import { Alert, AlertDescription } from "./ui/alert";
import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
} from "./ui/alert-dialog";
import { Badge } from "./ui/badge";
import { Button } from "./ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Skeleton } from "./ui/skeleton";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "./ui/table";

interface User {
	userId: string;
	nombreYapellido: string;
	email: string;
	tipo: string;
	estado: boolean;
}

const UserList = () => {
	const { user } = useAuth();
	const [users, setUsers] = useState<User[]>([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState<string | null>(null);
	const [userToDelete, setUserToDelete] = useState<string | null>(null);
	const itemsPerPage = 10;
	const navigate = useNavigate();

	useEffect(() => {
		fetchUsers();
	}, [currentPage]);

	const fetchUsers = async () => {
		setIsLoading(true);
		setError(null);
		try {
			const data = await getUsers(currentPage, itemsPerPage);
			setUsers(data.users);
			setTotalPages(data.totalPages);
		} catch (err) {
			setError("Error al cargar los usuarios");
		} finally {
			setIsLoading(false);
		}
	};

	const handleEdit = (userId: string) => {
		navigate(`/users/${userId}/edit`);
	};

	const handleDelete = async (userId: string) => {
		try {
			await deleteUser(userId);
			setUsers(users.filter((user) => user.userId !== userId));
			setUserToDelete(null);
		} catch (error) {
			setError("Error al eliminar el usuario");
		}
	};

	const canEditUser = (targetUser: User) => {
		return user?.tipo === "administrador" || user?.userId === targetUser.userId;
	};

	const canDeleteUser = (targetUser: User) => {
		return user?.tipo === "administrador" && user?.userId !== targetUser.userId;
	};

	if (!user || user.tipo !== "administrador") {
		navigate("/dashboard");
		return null;
	}

	return (
		<div className="container mx-auto px-4 py-8">
			<Card>
				<CardHeader className="flex flex-row items-center justify-between">
					<CardTitle>Lista de Usuarios</CardTitle>
					<Button
						onClick={() => navigate("/users/new")}
						className="flex items-center gap-2"
					>
						<Plus className="w-4 h-4" />
						Nuevo Usuario
					</Button>
				</CardHeader>

				<CardContent>
					{error && (
						<Alert variant="destructive" className="mb-6">
							<AlertDescription>{error}</AlertDescription>
						</Alert>
					)}

					{isLoading ? (
						<div className="space-y-2">
							{[...Array(5)].map((_, i) => (
								<Skeleton key={i} className="h-12 w-full" />
							))}
						</div>
					) : (
						<div className="rounded-md border">
							<Table>
								<TableHeader>
									<TableRow>
										<TableHead>Nombre y Apellido</TableHead>
										<TableHead>Email</TableHead>
										<TableHead>Tipo</TableHead>
										<TableHead>Estado</TableHead>
										<TableHead className="text-right">Acciones</TableHead>
									</TableRow>
								</TableHeader>
								<TableBody>
									{users.map((user) => (
										<TableRow key={user.userId}>
											<TableCell className="font-medium">
												{user.nombreYapellido}
											</TableCell>
											<TableCell>{user.email}</TableCell>
											<TableCell>
												<Badge variant="outline">
													{user.tipo.charAt(0).toUpperCase() +
														user.tipo.slice(1)}
												</Badge>
											</TableCell>
											<TableCell>
												<Badge
													variant={user.estado ? "default" : "destructive"}
												>
													{user.estado ? "Activo" : "Inactivo"}
												</Badge>
											</TableCell>
											<TableCell className="text-right">
												<div className="flex justify-end gap-2">
													{canEditUser(user) && (
														<Button
															variant="ghost"
															size="sm"
															onClick={() => handleEdit(user.userId)}
														>
															<Pencil className="w-4 h-4" />
														</Button>
													)}
													{canDeleteUser(user) && (
														<Button
															variant="ghost"
															size="sm"
															onClick={() => setUserToDelete(user.userId)}
															className="text-destructive hover:text-destructive/90"
														>
															<Trash2 className="w-4 h-4" />
														</Button>
													)}
												</div>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</div>
					)}

					{/* Paginación */}
					<div className="mt-4 flex items-center justify-between">
						<p className="text-sm text-muted-foreground">
							Mostrando {users.length} de {totalPages * itemsPerPage} usuarios
						</p>
						<div className="flex gap-2">
							<Button
								variant="outline"
								size="sm"
								onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
								disabled={currentPage === 1}
							>
								<ChevronLeft className="w-4 h-4" />
							</Button>
							<Button
								variant="outline"
								size="sm"
								onClick={() =>
									setCurrentPage((prev) => Math.min(prev + 1, totalPages))
								}
								disabled={currentPage === totalPages}
							>
								<ChevronRight className="w-4 h-4" />
							</Button>
						</div>
					</div>
				</CardContent>
			</Card>

			<AlertDialog
				open={!!userToDelete}
				onOpenChange={() => setUserToDelete(null)}
			>
				<AlertDialogContent>
					<AlertDialogHeader>
						<AlertDialogTitle>¿Está seguro?</AlertDialogTitle>
						<AlertDialogDescription>
							Esta acción no se puede deshacer. El usuario será eliminado
							permanentemente del sistema.
						</AlertDialogDescription>
					</AlertDialogHeader>
					<AlertDialogFooter>
						<AlertDialogCancel>Cancelar</AlertDialogCancel>
						<AlertDialogAction
							onClick={() => userToDelete && handleDelete(userToDelete)}
							className="bg-destructive text-destructive-foreground hover:bg-destructive/90"
						>
							Eliminar
						</AlertDialogAction>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialog>
		</div>
	);
};

export default UserList;
