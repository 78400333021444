import React, { useEffect } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'

interface ProtectedRouteProps {
  children: React.ReactNode
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { user, isLoading, logout } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (!isLoading && !user) {
      logout()
      navigate('/login', { replace: true })
    }
  }, [user, isLoading, logout, navigate])

  if (isLoading) {
    return <div>Cargando...</div>
  }

  if (!user) {
    return null // El useEffect se encargará de la redirección
  }


  return <>{children}</>
}

export default ProtectedRoute