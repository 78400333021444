import { Activity, Calendar, DollarSign, FileText, Users } from "lucide-react";
import { useEffect, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import { getAnesthesiaReport } from "../services/api";
import { Alert, AlertDescription } from "./ui/alert";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Skeleton } from "./ui/skeleton";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./ui/tabs";

interface DashboardStats {
  totalAnestesias: number;
  anestesiasPagadas: number;
  montoTotal: number;
  pacientesUnicos: number;
  topObrasSociales: Array<{
    nombre: string;
    cantidad: number;
    montoTotal: number;
  }>;
  topProcedimientos: Array<{
    nombre: string;
    cantidad: number;
  }>;
}

const DashboardStats = () => {
  const { user } = useAuth();
  const [stats, setStats] = useState<DashboardStats>({
    totalAnestesias: 0,
    anestesiasPagadas: 0,
    montoTotal: 0,
    pacientesUnicos: 0,
    topObrasSociales: [],
    topProcedimientos: [],
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchStats();
  }, []);

  const fetchStats = async () => {
    setIsLoading(true);
    try {
      // Obtener el primer día del mes actual
      const firstDay = new Date();
      firstDay.setDate(1);
      
      // Obtener el último día del mes actual
      const lastDay = new Date();
      lastDay.setMonth(lastDay.getMonth() + 1);
      lastDay.setDate(0);

      const data = await getAnesthesiaReport({
        dateFrom: firstDay.toISOString().split('T')[0],
        dateTo: lastDay.toISOString().split('T')[0],
        anestesistaId: user.tipo === "administrador" ? undefined : user.userId,
      });

      // Calcular pacientes únicos
      const pacientesUnicos = new Set(data.anesthesias.map((a: any) => a.patient.dni)).size;

      // Calcular top obras sociales
      const obrasSociales = data.anesthesias.reduce((acc: any, curr: any) => {
        const os = curr.obraSocial?.nombre || 'Particular';
        if (!acc[os]) {
          acc[os] = { cantidad: 0, montoTotal: 0 };
        }
        acc[os].cantidad += 1;
        acc[os].montoTotal += curr.precio || 0;
        return acc;
      }, {});

      const topOS = Object.entries(obrasSociales)
        .map(([nombre, datos]: [string, any]) => ({
          nombre,
          ...datos,
        }))
        .sort((a, b) => b.cantidad - a.cantidad)
        .slice(0, 5);

      setStats({
        totalAnestesias: data.anesthesias.length,
        anestesiasPagadas: data.anesthesias.filter((a: any) => a.pagado).length,
        montoTotal: data.anesthesias.reduce((sum: any, a: any) => sum + (a.precio || 0), 0),
        pacientesUnicos,
        topObrasSociales: topOS,
        topProcedimientos: [],
      });
    } catch (err) {
      setError("Error al cargar las estadísticas");
    } finally {
      setIsLoading(false);
    }
  };

  if (error) {
    return (
      <Alert variant="destructive">
        <AlertDescription>{error}</AlertDescription>
      </Alert>
    );
  }

  return (
    <div className="space-y-6">
      <Card>
        <CardHeader>
          <CardTitle className="text-2xl font-bold">
            Panel de Control - {new Date().toLocaleDateString('es-AR', { month: 'long', year: 'numeric' })}
          </CardTitle>
        </CardHeader>
        <CardContent>
          {isLoading ? (
            <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
              {[...Array(4)].map((_, i) => (
                <Skeleton key={i} className="h-32" />
              ))}
            </div>
          ) : (
            <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
              <Card>
                <CardContent className="p-6">
                  <div className="flex items-center justify-between space-x-4">
                    <div>
                      <p className="text-sm font-medium text-muted-foreground mb-1">
                        Total Anestesias
                      </p>
                      <p className="text-2xl font-bold">{stats.totalAnestesias}</p>
                    </div>
                    <div className="p-3 bg-primary/10 rounded-full">
                      <FileText className="h-5 w-5 text-primary" />
                    </div>
                  </div>
                </CardContent>
              </Card>

              <Card>
                <CardContent className="p-6">
                  <div className="flex items-center justify-between space-x-4">
                    <div>
                      <p className="text-sm font-medium text-muted-foreground mb-1">
                        Anestesias Pagadas
                      </p>
                      <p className="text-2xl font-bold">
                        {stats.anestesiasPagadas}
                        <span className="text-sm font-normal text-muted-foreground ml-2">
                          ({((stats.anestesiasPagadas / stats.totalAnestesias) * 100).toFixed(1)}%)
                        </span>
                      </p>
                    </div>
                    <div className="p-3 bg-green-100 rounded-full">
                      <DollarSign className="h-5 w-5 text-green-600" />
                    </div>
                  </div>
                </CardContent>
              </Card>

              <Card>
                <CardContent className="p-6">
                  <div className="flex items-center justify-between space-x-4">
                    <div>
                      <p className="text-sm font-medium text-muted-foreground mb-1">
                        Monto Total
                      </p>
                      <p className="text-2xl font-bold">
                        ${stats.montoTotal.toLocaleString('es-AR')}
                      </p>
                    </div>
                    <div className="p-3 bg-blue-100 rounded-full">
                      <Activity className="h-5 w-5 text-blue-600" />
                    </div>
                  </div>
                </CardContent>
              </Card>

              <Card>
                <CardContent className="p-6">
                  <div className="flex items-center justify-between space-x-4">
                    <div>
                      <p className="text-sm font-medium text-muted-foreground mb-1">
                        Pacientes Atendidos
                      </p>
                      <p className="text-2xl font-bold">{stats.pacientesUnicos}</p>
                    </div>
                    <div className="p-3 bg-purple-100 rounded-full">
                      <Users className="h-5 w-5 text-purple-600" />
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
          )}
        </CardContent>
      </Card>

      <Tabs defaultValue="obras-sociales" className="w-full">
        <TabsList className="w-full justify-start">
          <TabsTrigger value="obras-sociales">Top Obras Sociales</TabsTrigger>
          <TabsTrigger value="procedimientos">Top Procedimientos</TabsTrigger>
        </TabsList>
        
        <TabsContent value="obras-sociales">
          <Card>
            <CardHeader>
              <CardTitle>Top 5 Obras Sociales</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {/* Tabla para pantallas medianas y grandes */}
                <div className="hidden md:block">
                  <table className="w-full">
                    <thead>
                      <tr>
                        <th className="text-left">Obra Social</th>
                        <th className="text-right">Cantidad</th>
                        <th className="text-right">Monto Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {stats.topObrasSociales.map((os, index) => (
                        <tr key={index}>
                          <td className="py-2">{os.nombre}</td>
                          <td className="text-right">{os.cantidad}</td>
                          <td className="text-right">${os.montoTotal.toLocaleString('es-AR')}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                {/* Cards para móviles */}
                <div className="md:hidden space-y-4">
                  {stats.topObrasSociales.map((os, index) => (
                    <Card key={index}>
                      <CardContent className="p-4">
                        <div className="flex flex-col space-y-2">
                          <p className="font-bold">{os.nombre}</p>
                          <div className="flex justify-between">
                            <span className="text-muted-foreground">Cantidad:</span>
                            <span>{os.cantidad}</span>
                          </div>
                          <div className="flex justify-between">
                            <span className="text-muted-foreground">Monto Total:</span>
                            <span>${os.montoTotal.toLocaleString('es-AR')}</span>
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  ))}
                </div>
              </div>
            </CardContent>
          </Card>
        </TabsContent>

        <TabsContent value="procedimientos">
          {/* Implementar vista similar para procedimientos */}
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default DashboardStats; 