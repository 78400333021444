import { Eye, Pencil, Plus, Search, Trash2 } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { deletePatient, getPatients } from "../services/api";
import { Alert, AlertDescription } from "./ui/alert";
import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
} from "./ui/alert-dialog";
import { Button } from "./ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Input } from "./ui/input";
import { Skeleton } from "./ui/skeleton";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "./ui/table";

interface Patient {
	patientId: string;
	nombreYapellido: string;
	dni: string;
}

const PatientList = () => {
	const { user } = useAuth();
	const [patients, setPatients] = useState<Patient[]>([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState<string | null>(null);
	const [searchTerm, setSearchTerm] = useState("");
	const itemsPerPage = 10;
	const navigate = useNavigate();
	const [patientToDelete, setPatientToDelete] = useState<string | null>(null);

	useEffect(() => {
		fetchPatients();
	}, [currentPage]);

	const fetchPatients = async () => {
		setIsLoading(true);
		setError(null);
		try {
			const data = await getPatients(currentPage, itemsPerPage, searchTerm);
			setPatients(data.pacientes);
			setTotalPages(data.totalPages);
		} catch (err) {
			setError("Error al cargar los pacientes");
		} finally {
			setIsLoading(false);
		}
	};

	const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setCurrentPage(1);
		fetchPatients();
	};

	const handleDelete = (id: string) => {
		setPatientToDelete(id);
	};

	const confirmDelete = async () => {
		if (!patientToDelete) return;

		setIsLoading(true);
		try {
			await deletePatient(patientToDelete);
			fetchPatients();
		} catch (err) {
			setError("Error al eliminar el paciente");
		} finally {
			setIsLoading(false);
			setPatientToDelete(null);
		}
	};

	return (
		<div className="container mx-auto px-4 py-8">
			<Card>
				<CardHeader className="flex flex-row items-center justify-between">
					<CardTitle className="text-2xl font-bold">
						Lista de Pacientes
					</CardTitle>
					<Button
						onClick={() => navigate("/patients/new")}
						className="hidden md:flex items-center gap-2"
					>
						<Plus className="w-4 h-4" />
						Registrar Paciente
					</Button>
				</CardHeader>

				<CardContent>
					{error && (
						<Alert variant="destructive" className="mb-6">
							<AlertDescription>{error}</AlertDescription>
						</Alert>
					)}

					<form onSubmit={handleSearch} className="flex gap-2 mb-6">
						<Input
							type="text"
							placeholder="Buscar por DNI o nombre"
							value={searchTerm}
							onChange={(e) => setSearchTerm(e.target.value)}
							className="flex-grow"
						/>
						<Button type="submit" variant="secondary">
							<Search className="w-4 h-4 mr-2" />
							Buscar
						</Button>
					</form>

					{isLoading ? (
						<div className="space-y-2">
							{[...Array(5)].map((_, i) => (
								<Skeleton key={i} className="h-12 w-full" />
							))}
						</div>
					) : (
						<div className="rounded-md border">
							<Table>
								<TableHeader>
									<TableRow>
										<TableHead>DNI</TableHead>
										<TableHead>Nombre y Apellido</TableHead>
										<TableHead className="text-right">Acciones</TableHead>
									</TableRow>
								</TableHeader>
								<TableBody>
									{patients.map((patient) => (
										<TableRow key={patient.patientId}>
											<TableCell>{patient.dni}</TableCell>
											<TableCell className="font-medium">
												{patient.nombreYapellido}
											</TableCell>
											<TableCell className="text-right">
												<div className="flex justify-end gap-2">
													<Button
														variant="ghost"
														size="sm"
														onClick={() =>
															navigate(`/patients/${patient.patientId}`)
														}
													>
														<Eye className="w-4 h-4" />
													</Button>
													<Button
														variant="ghost"
														size="sm"
														onClick={() =>
															navigate(`/patients/edit/${patient.patientId}`)
														}
													>
														<Pencil className="w-4 h-4" />
													</Button>
													<Button
														variant="ghost"
														size="sm"
														onClick={() => handleDelete(patient.patientId)}
													>
														<Trash2 className="w-4 h-4 text-red-500" />
													</Button>
												</div>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</div>
					)}

					{/* Paginación */}
					<div className="mt-4 flex items-center justify-between">
						<p className="text-sm text-gray-500">
							Mostrando {(currentPage - 1) * itemsPerPage + 1} a{" "}
							{Math.min(currentPage * itemsPerPage, patients.length)} de{" "}
							{patients.length} registros
						</p>
						<div className="flex gap-2">
							<Button
								variant="outline"
								size="sm"
								onClick={() => setCurrentPage(currentPage - 1)}
								disabled={currentPage === 1}
							>
								Anterior
							</Button>
							<Button
								variant="outline"
								size="sm"
								onClick={() => setCurrentPage(currentPage + 1)}
								disabled={currentPage === totalPages}
							>
								Siguiente
							</Button>
						</div>
					</div>
				</CardContent>
			</Card>

			{/* Botón flotante para móvil */}
			<Button
				onClick={() => navigate("/patients/new")}
				className="md:hidden fixed bottom-6 right-6 rounded-full w-14 h-14 shadow-lg"
			>
				<Plus className="w-6 h-6" />
			</Button>

			<AlertDialog
				open={!!patientToDelete}
				onOpenChange={() => setPatientToDelete(null)}
			>
				<AlertDialogContent>
					<AlertDialogHeader>
						<AlertDialogTitle>¿Está seguro?</AlertDialogTitle>
						<AlertDialogDescription>
							Esta acción no se puede deshacer. Se eliminará permanentemente el
							paciente del sistema.
						</AlertDialogDescription>
					</AlertDialogHeader>
					<AlertDialogFooter>
						<AlertDialogCancel>Cancelar</AlertDialogCancel>
						<AlertDialogAction
							onClick={confirmDelete}
							className="bg-destructive text-destructive-foreground hover:bg-destructive/90"
						>
							Eliminar
						</AlertDialogAction>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialog>
		</div>
	);
};

export default PatientList;
