import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import {
	ArrowLeft,
	FileDown,
	Trash2,
	Loader2,
	Search,
	Plus,
	X,
	FileIcon,
	FileText,
} from "lucide-react";
import {
	createAnesthesia,
	deleteAnesthesia,
	getAnesthesiaById,
	getArchivo,
	getHealthCares,
	searchPatientByDni,
	updateAnesthesia,
	getSignedUrl,
} from "../services/api";
import { Alert, AlertDescription } from "./ui/alert";
import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
} from "./ui/alert-dialog";
import { Button } from "./ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "./ui/select";
import { Textarea } from "./ui/textarea";

interface AnesthesiaFormData {
	fecha: string;
	paciente: string;
	patientId?: string;
	dni?: string;
	obraSocial: string;
	healthCareId?: string;
	codigo: string;
	complejidad: string;
	cirujano: string;
	cirugia: string;
	anestesia: string;
	observaciones: string;
	precio: number;
	vencimientoPago: string;
	pagado: boolean;
	archivos?: string[];
}

interface FileInfo {
	url: string;
	type: string;
}

const AnesthesiaForm: React.FC = () => {
	const { id } = useParams<{ id: string }>();
	const navigate = useNavigate();
	const { user } = useAuth();
	const [formData, setFormData] = useState<AnesthesiaFormData>({
		fecha: "",
		paciente: "",
		obraSocial: "",
		codigo: "",
		complejidad: "",
		cirujano: "",
		cirugia: "",
		anestesia: "",
		observaciones: "",
		precio: 0,
		vencimientoPago: "",
		pagado: false,
	});
	const [error, setError] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [isEditable, setIsEditable] = useState(!id);
	const [originalData, setOriginalData] = useState<AnesthesiaFormData | null>(
		null
	);
	const [fileUrl, setFileUrl] = useState<string | null>(null);
	const [dniInput, setDniInput] = useState("");
	const [isPatientFound, setIsPatientFound] = useState(false);
	const [isSearching, setIsSearching] = useState(false);
	const [healthCares, setHealthCares] = useState<
		Array<{ healthCareId: string; nombre: string }>
	>([]);
	const [newHealthCare, setNewHealthCare] = useState("");
	const [selectedHealthCare, setSelectedHealthCare] = useState<string>("");
	const [files, setFiles] = useState<File[]>([]);
	const [filesUrls, setFilesUrls] = useState<string[]>([]);
	const [showDeleteDialog, setShowDeleteDialog] = useState(false);
	const [signedUrls, setSignedUrls] = useState<{ [key: string]: string }>({});
	const [fileInfos, setFileInfos] = useState<{ [key: string]: FileInfo }>({});
	const [selectedPdfIndex, setSelectedPdfIndex] = useState<number | null>(null);
	const [archivosAEliminar, setArchivosAEliminar] = useState<string[]>([]);

	useEffect(() => {
		if (id) {
			fetchAnesthesia();
		}
	}, [id]);

	useEffect(() => {
		const fetchHealthCares = async () => {
			try {
				const data = await getHealthCares();

				console.log(data);

				setHealthCares(data.healthCares);
			} catch (error) {
				console.error("Error al cargar obras sociales:", error);
			}
		};
		fetchHealthCares();
	}, []);

	useEffect(() => {
		const loadSignedUrls = async () => {
			if (formData.archivos && formData.archivos.length > 0 && id) {
				const urls: { [key: string]: string } = {};
				for (const filePath of formData.archivos) {
					try {
						const signedUrl = await getSignedUrl(filePath, id);
						urls[filePath] = signedUrl;
					} catch (error) {
						console.error('Error al obtener URL firmada:', error);
					}
				}
				setSignedUrls(urls);
			}
		};

		loadSignedUrls();
	}, [formData.archivos, id]);

	const fetchAnesthesia = async () => {
		setIsLoading(true);
		try {
			const data = await getAnesthesiaById(id!);
			const localDateTime = new Date(data.fecha).toISOString().slice(0, 16);
			const formattedData = {
				...data,
				fecha: localDateTime,
				vencimientoPago: new Date(
					new Date(data.fecha).getTime() + 30 * 24 * 60 * 60 * 1000
				)
					.toISOString()
					.slice(0, 10),
				patientId: data.patient.patientId,
				paciente: data.patient.nombreYapellido,
				dni: data.patient.dni,
			};
			if (data.archivo) {
				if (data.archivos && data.archivos.length > 0) {
					setFilesUrls(data.archivos);
				  }
			}
			setFormData(formattedData);
			setOriginalData(formattedData);
			setDniInput(data.patient.dni || "");
			setSelectedHealthCare(data.healthCareId || "");
			setIsEditable(
				data.anestesistaId === user?.userId || user?.tipo === "administrador"
			);
		} catch (err) {
			setError("Error al cargar los datos de la anestesia");
		} finally {
			setIsLoading(false);
		}
	};

	const handleChange = (
		e: React.ChangeEvent<
			HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
		>
	) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
			...(name === "fecha" && {
				vencimientoPago: new Date(
					new Date(value).getTime() + 30 * 24 * 60 * 60 * 1000
				)
					.toISOString()
					.slice(0, 10),
			}),
		}));
	};

	const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			const newFiles = Array.from(e.target.files);
			setFiles(prev => [...prev, ...newFiles]);
		}
	};

	const removeFile = (index: number) => {
		setFiles(prev => prev.filter((_, i) => i !== index));
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		setIsLoading(true);
		setError("");
		const updatedFields = new FormData();

		try {
			const allowedFields = [
				"fecha",
				"paciente",
				"patientId",
				"dni",
				"obraSocial",
				"healthCareId",
				"codigo",
				"complejidad",
				"cirujano",
				"cirugia",
				"anestesia",
				"observaciones",
				"precio",
				"vencimientoPago",
				"pagado",
			];
			
			allowedFields.forEach((key) => {
				const formKey = key as keyof AnesthesiaFormData;

				if (key === "patientId" && !formData.patientId) {
					return;
				}

				if (key === "dni" && !id && isPatientFound) {
					updatedFields.append("dni", dniInput);
					return;
				}

				if (key === "obraSocial" || key === "healthCareId") {
					if (selectedHealthCare && selectedHealthCare !== "new") {
						if (key === "healthCareId") {
							updatedFields.append("healthCareId", selectedHealthCare);
						}
					} else if (key === "obraSocial" && newHealthCare.trim()) {
						updatedFields.append("obraSocial", newHealthCare);
					}
					return;
				}

				if (formData[formKey] !== undefined && formData[formKey] !== null) {
					updatedFields.append(key, formData[formKey]?.toString() || "");
				}
			});

			if (files.length > 0) {
				 // Agregar archivos
				 files.forEach(file => {
					updatedFields.append('archivos', file);
				  });
			}

			if (archivosAEliminar.length > 0) {
				updatedFields.append('archivosAEliminar', JSON.stringify(archivosAEliminar));
			}

			if (id) {
				await updateAnesthesia(id, updatedFields);
			} else {
				await createAnesthesia(updatedFields);
			}

			navigate("/anesthesias");
		} catch (err) {
			if (err instanceof Error) {
				setError(err.message);
			} else {
				setError("Ocurrió un error al guardar la anestesia");
			}
		} finally {
			setIsLoading(false);
		}
	};

	const handleDelete = async () => {
		setShowDeleteDialog(true);
	};

	const confirmDelete = async () => {
		setIsLoading(true);
		try {
			await deleteAnesthesia(id!);
			navigate("/anesthesias");
		} catch (err) {
			setError("Error al eliminar la anestesia");
		} finally {
			setIsLoading(false);
			setShowDeleteDialog(false);
		}
	};

	const searchPatient = async (dni: string) => {
		setIsSearching(true);
		try {
			const patient = await searchPatientByDni(dni);
			console.log(patient);

			if (patient) {
				setFormData((prev) => ({
					...prev,
					paciente: patient.nombreYapellido,
					patientId: patient.patientId,
				}));
				setIsPatientFound(false);
			} else {
				setIsPatientFound(true);
				setFormData((prev) => ({
					...prev,
					paciente: "",
					patientId: undefined,
				}));
			}
		} catch (error) {
			console.error("Error buscando paciente:", error);
			setIsPatientFound(true);
			setError("Error al buscar el paciente");
		} finally {
			setIsSearching(false);
		}
	};

	const handleDniChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const dni = e.target.value;
		setDniInput(dni);
		if (dni.length < 7) {
			setIsPatientFound(false);
			setIsSearching(false);
			setFormData((prev) => ({
				...prev,
				paciente: "",
				patientId: undefined,
			}));
		} else {
			searchPatient(dni);
		}
	};

	const handleHealthCareChange = (value: string) => {
		setSelectedHealthCare(value);

		if (value === "new") {
			setNewHealthCare("");
			setFormData((prev) => ({
				...prev,
				obraSocial: "",
				healthCareId: undefined,
			}));
		} else {
			const healthCare = healthCares.find((hc) => hc.healthCareId === value);
			setFormData((prev) => ({
				...prev,
				obraSocial: healthCare ? healthCare.nombre : "",
				healthCareId: value,
			}));
		}
	};

	const getFileInfo = async (url: string): Promise<FileInfo> => {
		try {
			const response = await fetch(url, { method: 'HEAD' });
			const contentType = response.headers.get('content-type') || '';
			return { url, type: contentType };
		} catch (error) {
			console.error('Error al obtener info del archivo:', error);
			return { url, type: '' };
		}
	};

	useEffect(() => {
		const loadFileInfos = async () => {
			if (formData.archivos && formData.archivos.length > 0 && id) {
				const infos: { [key: string]: FileInfo } = {};
				
				for (const filePath of formData.archivos) {
					try {
						const signedUrl = await getSignedUrl(filePath, id);
						const fileInfo = await getFileInfo(signedUrl);
						infos[filePath] = fileInfo;
					} catch (error) {
						console.error('Error al obtener información del archivo:', error);
					}
				}
				
				setFileInfos(infos);
			}
		};

		loadFileInfos();
	}, [formData.archivos, id]);

	const handleRemoveExistingFile = (filePath: string) => {
		setArchivosAEliminar(prev => [...prev, filePath]);
		setFormData(prev => ({
			...prev,
			archivos: prev.archivos?.filter(archivo => archivo !== filePath)
		}));
	};

	if (isLoading) {
		return <div className="text-center mt-8">Cargando...</div>;
	}
	console.log(formData);

	return (
		<Card className="max-w-4xl mx-auto mb-8">
			<CardHeader>
				<div className="flex items-center justify-between">
					<Button
						variant="ghost"
						onClick={() => navigate("/anesthesias")}
						className="flex items-center gap-2"
					>
						<ArrowLeft className="w-4 h-4" />
						Volver
					</Button>
					<CardTitle>
						{id ? (isEditable ? "Editar" : "Ver") : "Registrar"} Anestesia
					</CardTitle>
				</div>
			</CardHeader>
			<CardContent className="pb-8">
				{error && (
					<Alert variant="destructive" className="mb-6">
						<AlertDescription>{error}</AlertDescription>
					</Alert>
				)}

				<form onSubmit={handleSubmit} className="space-y-6">
					<div className="grid grid-cols-1 md:grid-cols-2 gap-6">
						{/* Columna 1 */}
						<div className="space-y-6">
							{/* Fecha */}
							<div className="space-y-2">
								<Label htmlFor="fecha">Fecha y Hora</Label>
								<Input
									type="datetime-local"
									id="fecha"
									name="fecha"
									value={formData.fecha}
									onChange={handleChange}
									disabled={!isEditable}
									required
								/>
							</div>

							{/* DNI y Paciente */}
							<div className="space-y-2">
								<Label htmlFor="dni">DNI del Paciente</Label>
								<div className="flex gap-2">
									<Input
										type="text"
										value={dniInput}
										onChange={handleDniChange}
										placeholder="Ingrese DNI"
										disabled={!!id}
										required
										className="w-full"
									/>
									{!id && (
										<Button
											type="button"
											onClick={() => searchPatient(dniInput)}
											disabled={
												!isEditable || isSearching || dniInput.length < 7
											}
											variant="secondary"
										>
											{isSearching ? (
												<Loader2 className="w-4 h-4 animate-spin" />
											) : (
												<Search className="w-4 h-4" />
											)}
										</Button>
									)}
								</div>
								<Input
									type="text"
									name="paciente"
									value={formData.paciente}
									onChange={handleChange}
									placeholder={
										!dniInput ? "Primero ingrese un DNI" : "Nombre del paciente"
									}
									disabled={!!id || !isPatientFound}
									className="w-full"
									required
								/>
							</div>

							{/* Obra Social */}
							<div className="space-y-2">
								<Label htmlFor="obraSocial">Obra Social</Label>
								<Select
									value={selectedHealthCare}
									onValueChange={(value) => handleHealthCareChange(value)}
									disabled={!isEditable}
								>
									<SelectTrigger className="w-full">
										<SelectValue placeholder="Seleccione obra social" />
									</SelectTrigger>
									<SelectContent>
										{healthCares.map((hc) => (
											<SelectItem key={hc.healthCareId} value={hc.healthCareId}>
												{hc.nombre}
											</SelectItem>
										))}
										<SelectItem value="new">Nueva Obra Social</SelectItem>
									</SelectContent>
								</Select>
								{(selectedHealthCare === "new" || healthCares.length === 0) && (
									<Input
										type="text"
										value={newHealthCare}
										onChange={(e) => setNewHealthCare(e.target.value)}
										placeholder="Nombre de la obra social"
										className="w-full"
										required
									/>
								)}
							</div>

							{/* Primera mitad de los campos */}
							<div className="space-y-2">
								<Label htmlFor="codigo">Código</Label>
								<Input
									type="text"
									name="codigo"
									value={formData.codigo}
									onChange={handleChange}
									disabled={!isEditable}
									required
								/>
							</div>

							<div className="space-y-2">
								<Label htmlFor="complejidad">Complejidad</Label>
								<Input
									type="text"
									name="complejidad"
									value={formData.complejidad}
									onChange={handleChange}
									disabled={!isEditable}
									required
								/>
							</div>

							<div className="space-y-2">
								<Label htmlFor="cirujano">Cirujano</Label>
								<Input
									type="text"
									name="cirujano"
									value={formData.cirujano}
									onChange={handleChange}
									disabled={!isEditable}
									required
								/>
							</div>
						</div>

						{/* Columna 2 */}
						<div className="space-y-6">
							<div className="space-y-2">
								<Label htmlFor="cirugia">Cirugía</Label>
								<Input
									type="text"
									name="cirugia"
									value={formData.cirugia}
									onChange={handleChange}
									disabled={!isEditable}
									required
								/>
							</div>

							<div className="space-y-2">
								<Label htmlFor="anestesia">Tipo de Anestesia</Label>
								<Input
									type="text"
									name="anestesia"
									value={formData.anestesia}
									onChange={handleChange}
									disabled={!isEditable}
									required
								/>
							</div>

							<div className="space-y-2">
								<Label htmlFor="precio">Precio</Label>
								<Input
									type="number"
									name="precio"
									value={formData.precio}
									onChange={handleChange}
									disabled={!isEditable}
									required
								/>
							</div>

							{/* Vencimiento de Pago */}
							<div className="space-y-2">
								<Label htmlFor="vencimientoPago">Vencimiento de Pago</Label>
								<Input
									type="date"
									name="vencimientoPago"
									value={formData.vencimientoPago}
									onChange={handleChange}
									disabled
									required
								/>
							</div>

							{/* Estado de Pago (solo para administradores) */}
							{user?.tipo === "administrador" && (
								<div className="space-y-2">
									<Label htmlFor="pagado">Estado de Pago</Label>
									<Select
										value={formData.pagado.toString()}
										onValueChange={(value) =>
											setFormData((prev) => ({
												...prev,
												pagado: value === "true",
											}))
										}
									>
										<SelectTrigger className="w-full">
											<SelectValue placeholder="Seleccione estado" />
										</SelectTrigger>
										<SelectContent>
											<SelectItem value="false">No Pagado</SelectItem>
											<SelectItem value="true">Pagado</SelectItem>
										</SelectContent>
									</Select>
								</div>
							)}
						</div>
					</div>

					{/* Campos que ocupan todo el ancho */}
					<div className="space-y-6">
						{/* Observaciones */}
						<div className="space-y-2">
							<Label htmlFor="observaciones">Observaciones</Label>
							<Textarea
								name="observaciones"
								value={formData.observaciones}
								onChange={handleChange}
								disabled={!isEditable}
								className="min-h-[100px]"
							/>
						</div>

						{/* Archivos */}
						<div className="space-y-2">
							<Label htmlFor="archivos">Archivos</Label>
							<Input
								type="file"
								onChange={handleFileChange}
								disabled={!isEditable}
								className="cursor-pointer"
								multiple
								accept="image/*,.pdf"
							/>
							{files.length > 0 && (
								<div className="mt-2 space-y-2">
									{files.map((file, index) => (
										<div key={index} className="flex items-center justify-between p-2 bg-muted rounded-md">
											<span className="text-sm truncate">{file.name}</span>
											<Button
												variant="ghost"
												size="sm"
												onClick={() => removeFile(index)}
												className="ml-2"
											>
												<X className="w-4 h-4" />
											</Button>
										</div>
									))}
								</div>
							)}
						</div>

						{/* Vista previa de archivos existentes */}
						{formData.archivos && formData.archivos.length > 0 && (
							<div className="mt-6">
								<h3 className="text-lg font-medium mb-2">Archivos adjuntos:</h3>
								<div className="grid grid-cols-1 md:grid-cols-2 gap-4">
									{formData.archivos.map((filePath, index) => {
										 const fileInfo = fileInfos[filePath];
										 if (!fileInfo) return null;
 
										 const isPdf = fileInfo.type.includes('pdf');
										return <div key={index} className="relative group">
											{isEditable && (
												<Button
													variant="destructive"
													size="icon"
													className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity"
													onClick={() => handleRemoveExistingFile(filePath)}
												>
													<X className="h-4 w-4" />
												</Button>
											)}
											{isPdf ? (
												<div className="flex items-center p-4 bg-muted rounded-lg">
													<FileText className="w-8 h-8 mr-2 text-red-600" />
													<span className="flex-1">Documento PDF</span>
													<a 
														href={signedUrls[filePath]}
														target="_blank"
														rel="noopener noreferrer"
														className="ml-2 text-blue-500 hover:underline"
													>
														Abrir
													</a>
												</div>
											) : (
												<img
													src={signedUrls[filePath]}
													alt={`Archivo adjunto ${index + 1}`}
													className="max-w-full h-auto rounded-lg"
												/>
											)}
										</div>
									})}
								</div>
							</div>
						)}
					</div>

					{/* Botones */}
					<div className="flex flex-col sm:flex-row justify-between items-center gap-4 pt-6">
						{isEditable && (
							<Button
								type="submit"
								disabled={isLoading}
								className="w-full sm:w-auto"
							>
								{isLoading ? (
									<>
										<Loader2 className="w-4 h-4 mr-2 animate-spin" />
										Guardando...
									</>
								) : id ? (
									"Actualizar"
								) : (
									"Registrar"
								)}
							</Button>
						)}
						{id && isEditable && (
							<Button
								type="button"
								onClick={handleDelete}
								disabled={isLoading}
								variant="destructive"
								className="w-full sm:w-auto"
							>
								<Trash2 className="w-4 h-4 mr-2" />
								Eliminar
							</Button>
						)}
					</div>
				</form>

				{fileUrl && !isEditable && (
					<div className="mt-6">
						<h3 className="text-lg font-medium mb-2">Archivo adjunto:</h3>
						<img
							src={fileUrl}
							alt="Archivo adjunto"
							className="max-w-full h-auto rounded-lg"
						/>
					</div>
				)}

				<AlertDialog open={showDeleteDialog} onOpenChange={setShowDeleteDialog}>
					<AlertDialogContent>
						<AlertDialogHeader>
							<AlertDialogTitle>¿Estás seguro?</AlertDialogTitle>
							<AlertDialogDescription>
								Esta acción no se puede deshacer. Se eliminará permanentemente
								la anestesia del sistema.
							</AlertDialogDescription>
						</AlertDialogHeader>
						<AlertDialogFooter>
							<AlertDialogCancel>Cancelar</AlertDialogCancel>
							<AlertDialogAction onClick={confirmDelete}>
								Eliminar
							</AlertDialogAction>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialog>
			</CardContent>
		</Card>
	);
};

export default AnesthesiaForm;
