import React, { createContext, useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { loginUser, logoutUser, registerUser } from '../services/api'
import * as jwtDecode from 'jwt-decode'

interface AuthContextType {
  user: any | null
  login: (email: string, password: string) => Promise<void>
  logout: () => void
  signup: (nombre_y_apellido: string, email: string, password: string, tipo: string) => Promise<void>
  isLoading: boolean
}

const AuthContext = createContext<AuthContextType | undefined>(undefined)

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<any | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate()

  useEffect(() => {
    const checkToken = () => {
      const token = localStorage.getItem('accessToken')
      if (token) {
        try {
          const decodedToken: any = jwtDecode.jwtDecode(token)
          const currentTime = Date.now() / 1000
          if (decodedToken.exp > currentTime) {
            setUser(JSON.parse(localStorage.getItem('user') || '{}'))
          } else {
            logout()
          }
        } catch (error) {
          console.error('Error al decodificar el token:', error)
          logout()
        }
      }
      setIsLoading(false)
    }

    checkToken()
    const intervalId = setInterval(checkToken, 60000) // Verificar cada minuto

    return () => clearInterval(intervalId)
  }, [])

  const login = async (email: string, password: string) => {
    setIsLoading(true)
    try {
      const userData = await loginUser(email, password)
      setUser(userData)
      localStorage.setItem('user', JSON.stringify(userData))
      navigate('/')
    } catch (error) {
      throw error
    } finally {
      setIsLoading(false)
    }
  }

  const logout = async () => {
    setIsLoading(true)
    try {
      await logoutUser()
      setUser(null)
      localStorage.removeItem('user')
      navigate('/login')
    } catch (error) {
      console.error('Error durante el cierre de sesión:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const signup = async (nombre_y_apellido: string, email: string, password: string, tipo: string) => {
    setIsLoading(true)
    try {
      const userData = await registerUser(nombre_y_apellido, email, password, tipo)
      setUser(userData)
      localStorage.setItem('user', JSON.stringify(userData))
      navigate('/dashboard')
    } catch (error) {
      throw error
    } finally {
      setIsLoading(false)
    }
  }

  const value = {
    user,
    login,
    logout,
    signup,
    isLoading
  }
  
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
export const useAuth = () => {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}