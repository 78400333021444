import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { createPatient, getPatient, updatePatient } from "../services/api";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import { Alert, AlertDescription } from "./ui/alert";
import { ArrowLeft, Loader2, Save } from "lucide-react";
import { Skeleton } from "./ui/skeleton";

export interface PatientFormData {
  nombreYapellido: string;
  dni: string;
}

interface PatientFormProps {
  isEditing?: boolean;
}

const PatientForm: React.FC<PatientFormProps> = ({ isEditing = false }) => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [formData, setFormData] = useState<PatientFormData>({
    nombreYapellido: "",
    dni: "",
  });
  const [originalData, setOriginalData] = useState<PatientFormData | null>(null);
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (isEditing && id) {
      fetchPatient();
    }
  }, [isEditing, id]);

  const fetchPatient = async () => {
    setIsLoading(true);
    try {
      const patientData = await getPatient(id!);
      setFormData(patientData);
      setOriginalData(patientData);
    } catch (err: any) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setError("");

    try {
      if (isEditing && id) {
        const changedData = getChangedData();
        if (Object.keys(changedData).length > 0) {
          await updatePatient(id, changedData);
        }
      } else {
        await createPatient(formData);
      }
      navigate("/patients");
    } catch (err: any) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const getChangedData = () => {
    if (!originalData) return formData;

    const changedData: Partial<PatientFormData> = {};
    (Object.keys(formData) as Array<keyof PatientFormData>).forEach((key) => {
      if (formData[key] !== originalData[key]) {
        changedData[key] = formData[key];
      }
    });

    return changedData;
  };

  if (isLoading && isEditing) {
    return (
      <Card className="max-w-2xl mx-auto">
        <CardHeader>
          <Skeleton className="h-8 w-64" />
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            <Skeleton className="h-10 w-full" />
            <Skeleton className="h-10 w-full" />
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className="max-w-2xl mx-auto">
      <CardHeader className="space-y-6">
        <div className="flex items-center justify-between">
          <Button
            variant="ghost"
            onClick={() => navigate("/patients")}
            className="flex items-center gap-2"
          >
            <ArrowLeft className="w-4 h-4" />
            Volver
          </Button>
          <CardTitle>
            {isEditing ? "Editar" : "Registrar"} Paciente
          </CardTitle>
        </div>
      </CardHeader>

      <CardContent>
        {error && (
          <Alert variant="destructive" className="mb-6">
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="space-y-2">
            <Label htmlFor="nombreYapellido">Nombre y Apellido</Label>
            <Input
              type="text"
              id="nombreYapellido"
              name="nombreYapellido"
              value={formData.nombreYapellido}
              onChange={handleChange}
              placeholder="Ingrese nombre y apellido"
              required
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor="dni">DNI</Label>
            <Input
              type="text"
              id="dni"
              name="dni"
              value={formData.dni}
              onChange={handleChange}
              placeholder="Ingrese DNI"
              required
            />
          </div>

          <div className="flex justify-end gap-3">
            <Button
              type="button"
              variant="outline"
              onClick={() => navigate("/patients")}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              disabled={isLoading}
              className="min-w-[120px]"
            >
              {isLoading ? (
                <>
                  <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                  Guardando...
                </>
              ) : (
                <>
                  <Save className="w-4 h-4 mr-2" />
                  {isEditing ? "Actualizar" : "Registrar"}
                </>
              )}
            </Button>
          </div>
        </form>
      </CardContent>
    </Card>
  );
};

export default PatientForm;
