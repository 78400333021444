import { Pencil, Plus, Trash2 } from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteHealthCare, getHealthCares } from "../services/api";
import { Alert, AlertDescription } from "./ui/alert";
import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
} from "./ui/alert-dialog";
import { Button } from "./ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Skeleton } from "./ui/skeleton";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "./ui/table";

interface HealthCare {
	healthCareId: string;
	nombre: string;
}

const HealthCareList = () => {
	const navigate = useNavigate();
	const [healthCares, setHealthCares] = useState<HealthCare[]>([]);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState<string | null>(null);
	const [healthCareToDelete, setHealthCareToDelete] = useState<string | null>(
		null
	);

	useEffect(() => {
		fetchHealthCares();
	}, []);

	const fetchHealthCares = async () => {
		setIsLoading(true);
		try {
			const data = await getHealthCares();
			setHealthCares(data.healthCares);
		} catch (err) {
			setError("Error al cargar las obras sociales");
		} finally {
			setIsLoading(false);
		}
	};

	const handleEdit = (id: string) => {
		navigate(`/health-care/edit/${id}`);
	};

	const handleDelete = async (id: string) => {
		try {
			await deleteHealthCare(id);
			setHealthCares(healthCares.filter((hc) => hc.healthCareId !== id));
			setHealthCareToDelete(null);
		} catch (err) {
			setError("Error al eliminar la obra social");
		}
	};

	return (
		<div className="container mx-auto px-4 py-8">
			<Card>
				<CardHeader className="flex flex-row items-center justify-between">
					<CardTitle className="text-2xl font-bold">Obras Sociales</CardTitle>
					<Button
						onClick={() => navigate("/health-care/new")}
						className="flex items-center gap-2"
					>
						<Plus className="w-4 h-4" />
						Nueva Obra Social
					</Button>
				</CardHeader>

				<CardContent>
					{error && (
						<Alert variant="destructive" className="mb-6">
							<AlertDescription>{error}</AlertDescription>
						</Alert>
					)}

					{isLoading ? (
						<div className="space-y-2">
							{[...Array(5)].map((_, i) => (
								<Skeleton key={i} className="h-12 w-full" />
							))}
						</div>
					) : (
						<div className="rounded-md border">
							<Table>
								<TableHeader>
									<TableRow>
										<TableHead>Nombre</TableHead>
										<TableHead className="w-[150px] text-right">
											Acciones
										</TableHead>
									</TableRow>
								</TableHeader>
								<TableBody>
									{healthCares.map((healthCare) => (
										<TableRow key={healthCare.healthCareId}>
											<TableCell className="font-medium">
												{healthCare.nombre}
											</TableCell>
											<TableCell className="text-right">
												<div className="flex justify-end gap-2">
													<Button
														variant="ghost"
														size="sm"
														onClick={() => handleEdit(healthCare.healthCareId)}
														className="hover:text-blue-700"
													>
														<Pencil className="w-4 h-4" />
													</Button>
													<Button
														variant="ghost"
														size="sm"
														onClick={() =>
															setHealthCareToDelete(healthCare.healthCareId)
														}
														className="hover:text-red-700"
													>
														<Trash2 className="w-4 h-4" />
													</Button>
												</div>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</div>
					)}

					{!isLoading && healthCares.length === 0 && (
						<div className="text-center py-6 text-gray-500">
							No hay obras sociales registradas
						</div>
					)}
				</CardContent>
			</Card>

			<AlertDialog
				open={!!healthCareToDelete}
				onOpenChange={() => setHealthCareToDelete(null)}
			>
				<AlertDialogContent>
					<AlertDialogHeader>
						<AlertDialogTitle>¿Estás seguro?</AlertDialogTitle>
						<AlertDialogDescription>
							Esta acción no se puede deshacer. La obra social será eliminada
							permanentemente.
						</AlertDialogDescription>
					</AlertDialogHeader>
					<AlertDialogFooter>
						<AlertDialogCancel>Cancelar</AlertDialogCancel>
						<AlertDialogAction
							onClick={() =>
								healthCareToDelete && handleDelete(healthCareToDelete)
							}
							className="bg-destructive text-destructive-foreground hover:bg-destructive/90"
						>
							Eliminar
						</AlertDialogAction>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialog>
		</div>
	);
};

export default HealthCareList;
