import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import AnesthesiaForm from "./components/AnesthesiaForm";
import AnesthesiaList from "./components/AnesthesiaList";
import Dashboard from "./components/Dashboard";
import DeletedAnesthesiaList from "./components/DeletedAnesthesiaList";
import HealthCareForm from "./components/HealthCareForm";
import HealthCareList from "./components/HealthCareList";
import Login from "./components/Login";
import PatientForm from "./components/PatientForm";
import PatientList from "./components/PatientList";
import ProtectedRoute from "./components/ProtectedRoute";
import Reports from "./components/Reports";
import UserForm from "./components/UserForm";
import UserList from "./components/UserList";
import { AuthProvider } from "./contexts/AuthContext";
import DashboardStats from "./components/DashboardStats";

const App: React.FC = () => {
	return (
		<Router>
			<AuthProvider>
				<Routes>
					<Route path="/login" element={<Login />} />
					{/* <Route path="/signup" element={<SignUp />} /> */}
					<Route
						path="/"
						element={
							<ProtectedRoute>
								<Dashboard />
							</ProtectedRoute>
						}
					>
						<Route index element={<DashboardStats />} />
						<Route path="anesthesias" element={<AnesthesiaList />} />
						<Route path="anesthesias/new" element={<AnesthesiaForm />} />
						<Route path="anesthesias/:id" element={<AnesthesiaForm />} />
						<Route path="anesthesias/:id/edit" element={<AnesthesiaForm />} />
						<Route path="users" element={<UserList />} />
						<Route path="users/new" element={<UserForm />} />
						<Route
							path="users/:id/edit"
							element={<UserForm isEditing={true} />}
						/>
						<Route path="reports" element={<Reports />} />
						<Route
							path="/anesthesias/deleted"
							element={<DeletedAnesthesiaList />}
						/>
						<Route path="patients" element={<PatientList />} />
						<Route path="patients/new" element={<PatientForm />} />
						<Route path="patients/:id" element={<PatientForm isEditing />} />
						<Route
							path="patients/edit/:id"
							element={<PatientForm isEditing />}
						/>
						<Route path="health-care" element={<HealthCareList />} />
						<Route path="health-care/new" element={<HealthCareForm />} />
						<Route
							path="health-care/edit/:id"
							element={<HealthCareForm isEditing />}
						/>
					</Route>
				</Routes>
			</AuthProvider>
		</Router>
	);
};

export default App;
