import { PatientFormData } from "../components/PatientForm";
import { ReportFilters } from "../components/Reports";
import { UserFormData } from "../components/UserForm";

const API_URL = process.env.REACT_APP_API_URL;

export const loginUser = async (email: string, password: string) => {
	const response = await fetch(`${API_URL}/users/login`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			email,
			password,
		}),
	});
	if (!response.ok) {
		throw new Error("Error en el inicio de sesión");
	}
	const data = await response.json();
	localStorage.setItem("accessToken", data.token);
	return data.user;
};

export const logoutUser = async () => {
	localStorage.removeItem("accessToken");
};

export const registerUser = async (
	nombre_y_apellido: string,
	email: string,
	password: string,
	tipo: string
) => {
	const response = await fetch(`${API_URL}/users/registro`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${localStorage.getItem("accessToken")}`, // Asumiendo que necesitas un token para registrar usuarios
		},
		body: JSON.stringify({
			nombre_y_apellido,
			email,
			password,
			tipo,
		}),
	});

	if (!response.ok) {
		const errorData = await response.json();
		throw new Error(errorData.message || "Error en el registro de usuario");
	}

	const data = await response.json();
	return data.user;
};

export const getAnesthesias = async (
	page: number,
	pageSize: number,
	patientSearch: string = ""
) => {
	const token = localStorage.getItem("accessToken");
	if (!token) {
		throw new Error("No hay token de acceso");
	}
	const response = await fetch(
		`${API_URL}/anestesias?page=${page}&pageSize=${pageSize}&paciente=${encodeURIComponent(
			patientSearch
		)}`,
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
	if (!response.ok) {
		throw new Error("Error al obtener las anestesias");
	}
	return response.json();
};

export const createAnesthesia = async (anesthesiaData: FormData) => {
	const token = localStorage.getItem("accessToken");
	if (!token) {
		throw new Error("No hay token de acceso");
	}
	const response = await fetch(`${API_URL}/anestesias`, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`,
		},
		body: anesthesiaData,
	});
	if (!response.ok) {
		const errorData = await response.json();
		throw new Error(errorData.message || "Error al crear la anestesia");
	}
	return response.json();
};

export const getUsers = async (page: number, pageSize: number) => {
	const token = localStorage.getItem("accessToken");
	if (!token) {
		throw new Error("No hay token de acceso");
	}
	const response = await fetch(
		`${API_URL}/users?page=${page}&pageSize=${pageSize}`,
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
	if (!response.ok) {
		throw new Error("Error al obtener los usuarios");
	}
	return response.json();
};

export const getAnesthesiaById = async (id: string) => {
	const token = localStorage.getItem("accessToken");
	if (!token) {
		throw new Error("No hay token de acceso");
	}
	const response = await fetch(`${API_URL}/anestesias/${id}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
	if (!response.ok) {
		throw new Error("Error al obtener la anestesia");
	}
	return response.json();
};

export const updateAnesthesia = async (
	id: string,
	anesthesiaData: FormData
) => {
	const token = localStorage.getItem("accessToken");
	if (!token) {
		throw new Error("No hay token de acceso");
	}
	const response = await fetch(`${API_URL}/anestesias/${id}`, {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${token}`,
		},
		body: anesthesiaData,
	});
	if (!response.ok) {
		throw new Error("Error al actualizar la anestesia");
	}
	return response.json();
};

export const getAnesthesiaReport = async (filters: ReportFilters) => {
	const token = localStorage.getItem("accessToken");

	if (!token) {
		throw new Error("No hay token de acceso");
	}

	const queryParams = new URLSearchParams();
	Object.entries(filters).forEach(([key, value]) => {
		if (value !== undefined && value !== "") {
			queryParams.append(key, value.toString());
		}
	});

	const response = await fetch(
		`${API_URL}/anestesias?${queryParams}&pageSize=1000`,
		{
			method: "GET",
			headers: {
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			},
		}
	);
	if (!response.ok) {
		throw new Error("Error al obtener el reporte de anestesias");
	}
	return response.json();
};

export const deleteAnesthesia = async (id: string) => {
	const token = localStorage.getItem("accessToken");
	if (!token) {
		throw new Error("No hay token de acceso");
	}
	const response = await fetch(`${API_URL}/anestesias/${id}`, {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
	if (!response.ok) {
		throw new Error("Error al eliminar la anestesia");
	}
	return response.json();
};

export const createUser = async (userData: Omit<UserFormData, "id">) => {
	const token = localStorage.getItem("accessToken");
	if (!token) {
		throw new Error("No hay token de acceso");
	}
	const response = await fetch(`${API_URL}/users/registro`, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(userData),
	});
	if (!response.ok) {
		const errorData = await response.json();
		throw new Error(errorData.message || "Error al crear el usuario");
	}
	return response.json();
};

export const getUser = async (id: string) => {
	const token = localStorage.getItem("accessToken");
	if (!token) {
		throw new Error("No hay token de acceso");
	}
	const response = await fetch(`${API_URL}/users/${id}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
	if (!response.ok) {
		throw new Error("Error al obtener el usuario");
	}
	return response.json();
};

export const updateUser = async (
	id: string,
	userData: Partial<UserFormData>
) => {
	const token = localStorage.getItem("accessToken");

	if (!token) {
		throw new Error("No hay token de acceso");
	}
	console.log("userData", userData);
	const response = await fetch(`${API_URL}/users/${id}`, {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(userData),
	});
	if (!response.ok) {
		throw new Error("Error al actualizar el usuario");
	}
	return response.json();
};

export const deleteUser = async (id: string) => {
	const token = localStorage.getItem("accessToken");
	if (!token) {
		throw new Error("No hay token de acceso");
	}
	const response = await fetch(`${API_URL}/users/${id}`, {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
	if (!response.ok) {
		throw new Error("Error al eliminar el usuario");
	}
	return response.json();
};

export const getDeletedAnesthesias = async (page: number, pageSize: number) => {
	const token = localStorage.getItem("accessToken");
	if (!token) {
		throw new Error("No hay token de acceso");
	}
	const response = await fetch(
		`${API_URL}/anestesias/deleted?page=${page}&pageSize=${pageSize}`,
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
	if (!response.ok) {
		throw new Error("Error al obtener las anestesias marcadas para eliminar");
	}
	return response.json();
};

export const permanentlyDeleteAnesthesia = async (id: string) => {
	const token = localStorage.getItem("accessToken");
	if (!token) {
		throw new Error("No hay token de acceso");
	}
	const response = await fetch(`${API_URL}/anestesias/${id}/permanent`, {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
	if (!response.ok) {
		throw new Error("Error al eliminar permanentemente la anestesia");
	}
	return response.json();
};

export const rejectAnesthesiaDeletion = async (id: string) => {
	const token = localStorage.getItem("accessToken");
	if (!token) {
		throw new Error("No hay token de acceso");
	}
	const response = await fetch(`${API_URL}/anestesias/${id}/reject-deletion`, {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
	if (!response.ok) {
		throw new Error("Error al rechazar la eliminación de la anestesia");
	}
	return response.json();
};

export const getArchivo = async (id: string): Promise<Blob> => {
	const token = localStorage.getItem("accessToken");
	if (!token) {
		throw new Error("No hay token de acceso");
	}
	const response = await fetch(`${API_URL}/anestesia/${id}/archivo`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
	if (!response.ok) {
		throw new Error("Error al obtener el archivo");
	}
	return response.blob();
};

export const searchPatientByDni = async (dni: string) => {
	const token = localStorage.getItem("accessToken");
	if (!token) {
		throw new Error("No hay token de acceso");
	}

	const response = await fetch(`${API_URL}/pacientes/dni/${dni}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});

	if (!response.ok) {
		if (response.status === 404) {
			return null;
		}
		throw new Error("Error al buscar el paciente");
	}

	return response.json();
};

interface HealthCare {
	currentPage: number;
	healthCares: Array<{ healthCareId: string; nombre: string }>;
	totalPages: number;
	totalHealthCares: number;
}

export const getHealthCares = async (): Promise<HealthCare> => {
	const token = localStorage.getItem("accessToken");
	if (!token) {
		throw new Error("No hay token de acceso");
	}
	const response = await fetch(`${API_URL}/health-care`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
	if (!response.ok) {
		throw new Error("Error al obtener las obras sociales");
	}
	return response.json();
};

export const createHealthCare = async (nombre: string): Promise<HealthCare> => {
	const token = localStorage.getItem("accessToken");
	if (!token) {
		throw new Error("No hay token de acceso");
	}
	const response = await fetch(`${API_URL}/health-care`, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify({ nombre }),
	});
	if (!response.ok) {
		throw new Error("Error al crear la obra social");
	}
	return response.json();
};

export const createPatient = async (patientData: PatientFormData) => {
	const token = localStorage.getItem("accessToken");
	if (!token) {
		throw new Error("No hay token de acceso");
	}

	const response = await fetch(`${API_URL}/pacientes`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(patientData),
	});

	if (!response.ok) {
		const error = await response.json();
		throw new Error(error.message || "Error al crear el paciente");
	}

	return response.json();
};

export const updatePatient = async (
	id: string,
	patientData: Partial<PatientFormData>
) => {
	const token = localStorage.getItem("accessToken");
	if (!token) {
		throw new Error("No hay token de acceso");
	}

	const response = await fetch(`${API_URL}/pacientes/${id}`, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(patientData),
	});

	if (!response.ok) {
		const error = await response.json();
		throw new Error(error.message || "Error al actualizar el paciente");
	}

	return response.json();
};

export const getPatient = async (id: string) => {
	const token = localStorage.getItem("accessToken");
	if (!token) {
		throw new Error("No hay token de acceso");
	}

	const response = await fetch(`${API_URL}/pacientes/${id}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});

	if (!response.ok) {
		const error = await response.json();
		throw new Error(error.message || "Error al obtener el paciente");
	}

	return response.json();
};

interface PatientsResponse {
	totalPacientes: number;
	totalPages: number;
	currentPage: number;
	pacientes: Patient[];
}

interface Patient {
	patientId: string;
	nombreYapellido: string;
	dni: string;
}

export const getPatients = async (
	page: number,
	pageSize: number,
	search?: string
) => {
	const token = localStorage.getItem("accessToken");
	if (!token) {
		throw new Error("No hay token de acceso");
	}

	const response = await fetch(
		`${API_URL}/pacientes?page=${page}&pageSize=${pageSize}${
			search ? `&search=${search}` : ""
		}`,
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
	if (!response.ok) {
		throw new Error("Error al obtener los pacientes");
	}

	return response.json() as Promise<PatientsResponse>;
};

export const getHealthCare = async (id: string) => {
	const token = localStorage.getItem("accessToken");
	if (!token) {
		throw new Error("No hay token de acceso");
	}
	const response = await fetch(`${API_URL}/health-care/${id}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
	if (!response.ok) {
		throw new Error("Error al obtener la obra social");
	}
	return response.json();
};

export const updateHealthCare = async (
	id: string,
	data: { nombre: string }
) => {
	const token = localStorage.getItem("accessToken");
	if (!token) {
		throw new Error("No hay token de acceso");
	}
	const response = await fetch(`${API_URL}/health-care/${id}`, {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(data),
	});
	if (!response.ok) {
		throw new Error("Error al actualizar la obra social");
	}
	return response.json();
};

export const deleteHealthCare = async (id: string): Promise<void> => {
	const token = localStorage.getItem("accessToken");
	if (!token) {
		throw new Error("No hay token de acceso");
	}
	const response = await fetch(`${API_URL}/health-care/${id}`, {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
	if (!response.ok) {
		throw new Error("Error al eliminar la obra social");
	}
};

export const deletePatient = async (id: string) => {
	const token = localStorage.getItem("accessToken");
	if (!token) {
		throw new Error("No hay token de acceso");
	}

	const response = await fetch(`${API_URL}/pacientes/${id}`, {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});

	if (!response.ok) {
		const error = await response.json();
		throw new Error(error.message || "Error al eliminar el paciente");
	}

	return response.json();
};

export const getSignedUrl = async (filePath: string, anesthesiaId: string): Promise<string> => {
	const token = localStorage.getItem("accessToken");
	if (!token) {
		throw new Error("No hay token de acceso");
	}
	
	const response = await fetch(
		`${API_URL}/anestesias/file/${encodeURIComponent(filePath)}?anesthesiaId=${anesthesiaId}`,
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
	
	if (!response.ok) {
		throw new Error("Error al obtener la URL firmada");
	}
	
	const { signedUrl } = await response.json();
	return signedUrl;
};

export const getStatistics = async (dateFrom: string, dateTo: string) => {
	const token = localStorage.getItem("accessToken");
	if (!token) {
		throw new Error("No hay token de acceso");
	}

	const response = await fetch(
		`${API_URL}/anestesias/statistics?dateFrom=${dateFrom}&dateTo=${dateTo}`,
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);

	if (!response.ok) {
		throw new Error("Error al obtener estadísticas");
	}
	return response.json();
};
