import { ArrowLeft, Loader2, Save } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
	createHealthCare,
	getHealthCare,
	updateHealthCare,
} from "../services/api";
import { Alert, AlertDescription } from "./ui/alert";
import { Button } from "./ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import { Skeleton } from "./ui/skeleton";

interface HealthCareFormData {
	nombre: string;
}

interface HealthCareFormProps {
	isEditing?: boolean;
}

const HealthCareForm: React.FC<HealthCareFormProps> = ({
	isEditing = false,
}) => {
	const { id } = useParams<{ id: string }>();
	const navigate = useNavigate();
	const [formData, setFormData] = useState<HealthCareFormData>({
		nombre: "",
	});
	const [error, setError] = useState<string | null>(null);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (isEditing && id) {
			fetchHealthCare();
		}
	}, [isEditing, id]);

	const fetchHealthCare = async () => {
		setIsLoading(true);
		try {
			const data = await getHealthCare(id!);
			setFormData({ nombre: data.nombre });
		} catch (err) {
			setError("Error al cargar la obra social");
		} finally {
			setIsLoading(false);
		}
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		setIsLoading(true);
		setError(null);

		try {
			if (isEditing && id) {
				await updateHealthCare(id, formData);
			} else {
				await createHealthCare(formData.nombre);
			}
			navigate("/health-care");
		} catch (err: any) {
			console.log(err);

			if (err.message === "Error al crear la obra social") {
				setError("Ya existe una obra social con ese nombre");
			} else {
				setError(err.message || "Error al guardar la obra social");
			}
		} finally {
			setIsLoading(false);
		}
	};

	if (isLoading && isEditing) {
		return (
			<Card className="max-w-2xl mx-auto">
				<CardHeader>
					<Skeleton className="h-8 w-64" />
				</CardHeader>
				<CardContent>
					<div className="space-y-4">
						<Skeleton className="h-10 w-full" />
						<Skeleton className="h-10 w-full" />
					</div>
				</CardContent>
			</Card>
		);
	}

	return (
		<Card className="max-w-2xl mx-auto">
			<CardHeader className="space-y-6">
				<div className="flex items-center justify-between">
					<Button
						variant="ghost"
						onClick={() => navigate("/health-care")}
						className="flex items-center gap-2"
					>
						<ArrowLeft className="w-4 h-4" />
						Volver
					</Button>
					<CardTitle>{isEditing ? "Editar" : "Nueva"} Obra Social</CardTitle>
				</div>
			</CardHeader>

			<CardContent>
				<form onSubmit={handleSubmit} className="space-y-6">
					{error && (
						<Alert variant="destructive">
							<AlertDescription>{error}</AlertDescription>
						</Alert>
					)}

					<div className="space-y-2">
						<Label htmlFor="nombre">Nombre de la Obra Social</Label>
						<Input
							type="text"
							id="nombre"
							name="nombre"
							value={formData.nombre}
							onChange={handleChange}
							placeholder="Ingrese el nombre de la obra social"
							className="w-full"
							required
						/>
					</div>

					<div className="flex justify-end gap-3">
						<Button
							type="button"
							variant="outline"
							onClick={() => navigate("/health-care")}
						>
							Cancelar
						</Button>
						<Button
							type="submit"
							disabled={isLoading}
							className="min-w-[120px]"
						>
							{isLoading ? (
								<>
									<Loader2 className="w-4 h-4 mr-2 animate-spin" />
									Guardando...
								</>
							) : (
								<>
									<Save className="w-4 h-4 mr-2" />
									{isEditing ? "Actualizar" : "Crear"}
								</>
							)}
						</Button>
					</div>
				</form>
			</CardContent>
		</Card>
	);
};

export default HealthCareForm;
